import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSigninCheck } from "reactfire";
import { useImmer } from "use-immer";

import _ from "lodash";
import { createAccounts } from "api";
import { Tuple } from "common/types";
import { triggerDownload } from "utils";
import styles from "./account-create-dialogue.module.scss";

const ALPHANUMERIC = "abcdefghijklmnopqrstuvwxyz0123456789";

type NameOption = "default" | "manual";
type IdentifierOption = "prefix" | "suffix";
type PasswordOption = "identical" | "random";

interface AccountCreateDialogueProps {
  onCreated: () => void;
  onCancelled: () => void;
}

export default function AccountCreateDialogue(props: AccountCreateDialogueProps) {
  const { data: signinCheckResult } = useSigninCheck();

  const formRef = useRef<HTMLFormElement>(null);
  const domainRef = useRef<HTMLInputElement>(null);
  const baseNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const [count, setCount] = useState(1);
  const [domain, setDomain] = useState("");
  const [nameOption, setNameOption] = useState<NameOption>("default");
  const [baseName, setBaseName] = useState("");
  const [identifierOption, setIdentifierOption] = useState<IdentifierOption>("prefix");
  const [passwordOption, setPasswordOption] = useState<PasswordOption>("identical");
  const [manualNames, setManualNames] = useImmer<string[]>([]);
  const [password, setPassword] = useState("");
  const [verified, setVerified] = useState(true);

  const generateEmailWithIdentifier = useCallback(
    (idx: number) => (identifierOption === "prefix" ? `${idx}${baseName}` : `${baseName}${idx}`),
    [baseName, identifierOption]
  );

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const accountDetails = _.range(0, count).map(i =>
        Tuple([
          `${nameOption === "default" ? generateEmailWithIdentifier(i) : manualNames[i]}@${domain}`,
          passwordOption === "identical" ? password : _.sampleSize(ALPHANUMERIC, 8).join("")
        ] as const)
      );

      if (passwordOption === "random") {
        triggerDownload(
          new Blob([accountDetails.map(detail => detail.join(", ")).join("\n")], { type: "text/plain" }),
          "account-details.txt"
        );
      }

      await createAccounts({
        requesterIdToken: await signinCheckResult.user!.getIdToken(true),
        accountDetails,
        verified
      });
      props.onCreated();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      count,
      domain,
      generateEmailWithIdentifier,
      manualNames,
      nameOption,
      password,
      passwordOption,
      props.onCreated,
      signinCheckResult.user,
      verified
    ]
  );

  useEffect(() => {
    domainRef.current?.setCustomValidity(/^\S+\.\S+$/i.test(domainRef.current.value) ? "" : "Invalid domain");
    baseNameRef.current?.setCustomValidity(/^\S+$/i.test(baseNameRef.current.value) ? "" : "Invalid base name");
    passwordRef.current?.setCustomValidity(
      passwordRef.current.value.length < 6 ? "Password must be at least 6 characters long" : ""
    );
  }, []);

  const defaultEmailPreview = useMemo(
    () =>
      _.range(0, 3)
        .map(i => `${generateEmailWithIdentifier(i)}@${domain}`)
        .join("\n"),
    [domain, generateEmailWithIdentifier]
  );

  return (
    <form ref={formRef} id={styles.main} onSubmit={formSubmitHandler}>
      <div>
        <label htmlFor="count">Number of accounts:</label>
        <input
          type="number"
          id="count"
          min="1"
          max="30"
          value={count}
          onChange={e => setCount(e.target.valueAsNumber)}
        />
      </div>
      <div>
        <label htmlFor="domain">Email domain:</label>
        <input
          ref={domainRef}
          type="text"
          id="domain"
          placeholder="Example: gmail.com"
          value={domain}
          onChange={e => {
            setDomain(e.target.value);
            e.target.setCustomValidity(/^\S+\.\S+$/i.test(e.target.value) ? "" : "Invalid domain");
          }}
        />
      </div>
      <div>
        <label htmlFor="name-option">Name option:</label>
        <select id="name-option" value={nameOption} onChange={e => setNameOption(e.target.value as NameOption)}>
          <option value="default">Default</option>
          <option value="manual">Manual</option>
        </select>
      </div>
      <div>
        <label htmlFor="password-option">Password option:</label>
        <select
          id="password-option"
          value={passwordOption}
          onChange={e => setPasswordOption(e.target.value as PasswordOption)}
        >
          <option value="identical">Identical</option>
          <option value="random">Random</option>
        </select>
      </div>
      {nameOption === "default" ? (
        <>
          <div>
            <label htmlFor="base-name">Base name:</label>
            <input
              ref={baseNameRef}
              type="text"
              id="base-name"
              value={baseName}
              onChange={e => {
                setBaseName(e.target.value);
                e.target.setCustomValidity(/^\S+$/i.test(e.target.value) ? "" : "Invalid base name");
              }}
            />
          </div>
          <div>
            <span style={{ marginRight: 8 }}>Identifier type:</span>
            <label htmlFor="prefix">Prefix</label>
            <input
              type="radio"
              id="prefix"
              name="identifier-type"
              checked={identifierOption === "prefix"}
              onClick={() => setIdentifierOption("prefix")}
            />
            <label htmlFor="suffix">Suffix</label>
            <input
              type="radio"
              id="suffix"
              name="identifier-type"
              checked={identifierOption === "suffix"}
              onClick={() => setIdentifierOption("suffix")}
            />
          </div>
        </>
      ) : (
        <div id={styles["manual-names"]}>
          {_.range(0, count).map(i => (
            <div key={i}>
              <label>Name {i + 1}</label>
              <input
                type="text"
                value={manualNames.at(i) ?? ""}
                onChange={e => {
                  setManualNames(manualNames => {
                    manualNames[i] = e.target.value;
                  });
                  e.target.setCustomValidity(/^[a-z0-9]+$/i.test(e.target.value) ? "" : "Invalid name");
                }}
              />
            </div>
          ))}
        </div>
      )}
      {passwordOption === "identical" ? (
        <div>
          <label htmlFor="password">Password:</label>
          <input
            ref={passwordRef}
            type="password"
            id="password"
            value={password}
            onChange={e => {
              setPassword(e.target.value);
              e.target.setCustomValidity(
                e.target.value.length < 6 ? "Password must be at least 6 characters long" : ""
              );
            }}
          />
        </div>
      ) : (
        <div>A file with username-password pairs will be generated.</div>
      )}
      <div>
        <label htmlFor="verify">Create with email verified:</label>
        <input type="checkbox" id="verify" checked={verified} onChange={e => setVerified(e.target.checked)} />
      </div>
      {nameOption === "default" && formRef.current?.checkValidity() && (
        <div className="subtext" style={{ whiteSpace: "pre" }}>
          {"The new accounts will have emails like the following:\n"}
          {defaultEmailPreview}
          {"\n..."}
        </div>
      )}
      <div id={styles["btn-container"]}>
        <button type="submit">Create</button>
        <button type="button" onClick={props.onCancelled}>
          Cancel
        </button>
      </div>
    </form>
  );
}
