import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import { FirebaseError } from "firebase/app";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useAuth, useFirestore, useSigninCheck } from "reactfire";
import { isFirebaseError } from "common/utils/firebase";
import { getGenericConverter } from "utils/firebase";
import type { EmailAllowListDoc } from "common/types/auth";

import LoadingOverlay from "components/loading-overlay";
import styles from "./login.module.scss";

interface LoginProps {
  type?: "login" | "register" | "reset-password";
}

const VARS = Object.freeze({
  login: {
    title: "Welcome back!",
    help: (
      <>
        No account yet? Sign up <Link to="/register">here</Link>.
      </>
    ),
    submit: "Sign in"
  },
  register: {
    title: "Register",
    help: (
      <>
        Already have an account? Log in <Link to="/login">here</Link>.
      </>
    ),
    submit: "Create account"
  },
  "reset-password": {
    title: "Reset password",
    help: (
      <>
        No account yet? Sign up <Link to="/register">here</Link>.
      </>
    ),
    submit: "Send"
  }
});

export default function Login(_props: LoginProps) {
  const props = { type: "login" as const, ..._props };
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAuth();
  const db = useFirestore();
  const { status: signinCheckStatus, data: signinCheckResult } = useSigninCheck();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [persistLogin, setPersistLogin] = useState(props.type === "register");
  const [error, setError] = useState<string | null>(null);

  const [persistSpanRef, nonPersistSpanRef] = [useRef<HTMLSpanElement>(null), useRef<HTMLSpanElement>(null)];
  const [persistSpanInDOM, setPersistSpanInDOM] = useState(false);
  const [nonPersistSpanInDOM, setNonPersistSpanInDOM] = useState(false);

  const [resetPasswordEmailSent, setResetPasswordEmailSent] = useState(false);
  const resetPasswordOobCode = useMemo(() => searchParams.get("oobCode"), [searchParams]);
  const [resetPasswordComplete, setResetPasswordComplete] = useState(false);

  useEffect(() => {
    if (signinCheckResult?.signedIn) {
      navigate(signinCheckResult.user.emailVerified ? "/" : "/verify");
    }
  }, [signinCheckResult?.signedIn, navigate, signinCheckResult?.user?.emailVerified]);

  useEffect(() => {
    setError(null);
    setEmail("");
    setPassword("");

    if (props.type !== "reset-password") {
      setResetPasswordEmailSent(false);
      setResetPasswordComplete(false);
    }
  }, [props.type]);

  useEffect(() => {
    setPersistence(
      auth,
      persistLogin || props.type === "register" ? browserLocalPersistence : browserSessionPersistence
    );
  }, [auth, persistLogin, props.type]);

  const formSubmitHandler = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError(null);

      try {
        switch (props.type) {
          case "login":
            await signInWithEmailAndPassword(auth, email, password);
            break;

          case "register":
            if (process.env.NODE_ENV === "development") {
              // Emulates the blocking function that comes with Firebase + Identity Platform
              // since there is no emulator for this yet
              const data = (
                await getDoc(doc(db, "auth/email-allowlist").withConverter(getGenericConverter<EmailAllowListDoc>()))
              ).data();

              if (!data) {
                console.warn("Email allowlist doc not found");
                throw new FirebaseError("auth/internal-error", "Internal error");
              }

              if (!(data.domains.includes(email.split("@")[1]) || data.emails.includes(email))) {
                throw new FirebaseError(
                  "auth/permission-denied",
                  "Account registration is not available for your email address."
                );
              }
            }

            const credential = await createUserWithEmailAndPassword(auth, email, password);
            await sendEmailVerification(credential.user);
            break;

          case "reset-password":
            if (resetPasswordOobCode) {
              await confirmPasswordReset(auth, resetPasswordOobCode, password);
              setResetPasswordComplete(true);
            } else {
              try {
                await sendPasswordResetEmail(auth, email);
              } catch (e) {
                if (process.env.NODE_ENV === "development") {
                  console.error(e);
                }
              } finally {
                // Always show the success message
                setResetPasswordEmailSent(true);
              }
            }
            break;
        }
      } catch (e: any) {
        console.error({ e });

        setError(() => {
          if (isFirebaseError(e)) {
            switch (e.code) {
              case "auth/missing-email":
                return "Email is required.";

              case "auth/user-not-found":
              case "auth/wrong-password":
                return "Invalid email or password.";

              case "auth/email-already-in-use":
                return "Email is already in use.";

              case "auth/permission-denied":
                return e.message;

              default:
                return "An unknown error occurred.";
            }
          } else {
            return "An unknown error occurred.";
          }
        });
      }
    },
    [auth, db, email, password, props.type, resetPasswordOobCode]
  );

  return (
    <div id={styles.main}>
      {signinCheckStatus === "loading" && <LoadingOverlay />}
      <section>
        <h1>{VARS[props.type].title}</h1>
        {props.type === "reset-password" && (
          <p>
            {resetPasswordOobCode ? (
              resetPasswordComplete ? (
                <>
                  Your password has been successfully reset. <Link to="/login">Click here</Link> to log in again.
                </>
              ) : (
                "Enter your new password below."
              )
            ) : resetPasswordEmailSent ? (
              "We've sent you the email. Simply follow the instructions to reset your password."
            ) : (
              "Enter the email address that you used to register, and we will send you an email with a link to " +
              "reset your password."
            )}
          </p>
        )}
        {!(resetPasswordEmailSent || resetPasswordComplete) && (
          <form onSubmit={formSubmitHandler}>
            {!resetPasswordOobCode && (
              <div style={resetPasswordOobCode ? { display: "none" } : undefined}>
                <input
                  type="email"
                  id="email"
                  autoComplete={props.type === "register" ? "new-email" : "current-email"}
                  placeholder=" "
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
                <label htmlFor="email">Email</label>
              </div>
            )}
            {(props.type !== "reset-password" || resetPasswordOobCode) && (
              <div>
                <input
                  type="password"
                  id="password"
                  autoComplete={props.type === "register" ? "new-password" : "current-password"}
                  placeholder=" "
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    e.target.setCustomValidity(
                      e.target.value.length >= 6 ? "" : "Password must be at least 6 characters long."
                    );
                  }}
                  required
                />
                <label htmlFor="password">Password</label>
              </div>
            )}
            {(props.type === "register" || resetPasswordOobCode) && (
              <div>
                <input
                  type="password"
                  id="confirm-password"
                  autoComplete="confirm-password"
                  placeholder=" "
                  value={confirmPassword}
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                    e.target.setCustomValidity(e.target.value === password ? "" : "Passwords do not match.");
                  }}
                  required
                />
                <label htmlFor="confirm-password">Confirm password</label>
              </div>
            )}
            {props.type === "login" && (
              <div id={styles.persistence}>
                <div>
                  <label htmlFor="persistence">Remember me</label>
                  <input
                    type="checkbox"
                    id="persistence"
                    onChange={e => setPersistLogin(e.target.checked)}
                    checked={persistLogin}
                  />
                </div>
                <div className="subtext">
                  Log in will{" "}
                  <CSSTransition
                    in={persistLogin && !nonPersistSpanInDOM}
                    nodeRef={persistSpanRef}
                    timeout={250}
                    classNames="float-transition"
                    onEnter={() => setPersistSpanInDOM(true)}
                    onExited={() => setPersistSpanInDOM(false)}
                    mountOnEnter
                    unmountOnExit
                    appear
                  >
                    <span ref={persistSpanRef}>be saved for 30 days</span>
                  </CSSTransition>
                  <CSSTransition
                    in={!persistLogin && !persistSpanInDOM}
                    nodeRef={nonPersistSpanRef}
                    timeout={250}
                    classNames="float-transition"
                    onEnter={() => setNonPersistSpanInDOM(true)}
                    onExited={() => setNonPersistSpanInDOM(false)}
                    mountOnEnter
                    unmountOnExit
                    appear
                  >
                    <span ref={nonPersistSpanRef}>not be saved</span>
                  </CSSTransition>
                </div>
              </div>
            )}
            <button type="submit" disabled={resetPasswordEmailSent}>
              {resetPasswordOobCode ? "Update password" : VARS[props.type].submit}
            </button>
          </form>
        )}
        {props.type === "login" && <Link to="/reset-password">Forgot password?</Link>}
      </section>
      {error && <div className="error">{error}</div>}
      {!(resetPasswordEmailSent || resetPasswordOobCode) && <div>{VARS[props.type].help}</div>}
    </div>
  );
}
