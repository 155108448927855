import { HTMLAttributes, ReactNode } from "react";
import { Portal } from "react-portal";
import styles from "./fullscreen-prompt.module.scss";

interface FullscreenPromptProps extends HTMLAttributes<HTMLDivElement> {
  onClickOutside?: () => void;
  children?: ReactNode;
}

export default function FullscreenPrompt(props: FullscreenPromptProps) {
  const { onClickOutside, children, ...rest } = props;
  return (
    <Portal>
      <div
        id={styles.main}
        onClick={e => {
          if (e.target === e.currentTarget) {
            props.onClickOutside?.();
          }
        }}
      >
        <div {...rest}>{props.children}</div>
      </div>
    </Portal>
  );
}
