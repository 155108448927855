import { useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSigninCheck } from "reactfire";
import { useImmer } from "use-immer";

import AccountCreateDialogue from "./account-create-dialogue";
import SelectableTable from "components/selectable-table";
import FullscreenPrompt from "components/fullscreen-prompt";

import _ from "lodash";
import { deleteUsers, forceVerifyUserEmail, getAllUsers } from "api";

import styles from "./admin.module.scss";

const enum PromptType {
  CREATE_ACCOUNTS = 1,
  VERIFY_SELECTED,
  DELETE_SELECTED
}

export default function Admin() {
  const { status: signinCheckStatus, data: signinCheckResult } = useSigninCheck();
  const userList = useInfiniteQuery(
    "userList",
    async ({ pageParam }) =>
      (
        await getAllUsers({
          requesterIdToken: await signinCheckResult.user!.getIdToken(true),
          nextPageToken: pageParam
        })
      ).data,
    {
      getNextPageParam: lastPage => lastPage.nextPageToken,
      enabled: signinCheckStatus === "success",
      retry: false
    }
  );
  const userListResult = useMemo(
    () => (userList.isSuccess ? _.sortBy(userList.data.pages.map(page => page.users).flat(), user => user.email) : []),
    [userList.data?.pages, userList.isSuccess]
  );

  const [promptType, setPromptType] = useState<PromptType | null>(null);
  const [selection, setSelection] = useImmer(new Set<number>());

  return (
    <section id={styles.main}>
      {promptType && (
        <FullscreenPrompt onClickOutside={() => setPromptType(null)}>
          {promptType === PromptType.CREATE_ACCOUNTS ? (
            <AccountCreateDialogue
              onCreated={() => {
                setPromptType(null);
                userList.refetch();
              }}
              onCancelled={() => setPromptType(null)}
            />
          ) : promptType === PromptType.VERIFY_SELECTED ? (
            <>
              <h3>Confirm force verify selected accounts?</h3>
              <div>
                <button
                  onClick={async () => {
                    setPromptType(null);
                    await forceVerifyUserEmail({
                      requesterIdToken: await signinCheckResult.user!.getIdToken(true),
                      uids: [...selection.values()].map(idx => userListResult[idx].uid)
                    });
                    userList.refetch();
                  }}
                >
                  Confirm
                </button>
                <button onClick={() => setPromptType(null)}>Cancel</button>
              </div>
            </>
          ) : (
            <>
              <h3>Confirm delete selected accounts?</h3>
              <div>
                <button
                  onClick={async () => {
                    setPromptType(null);
                    try {
                      await deleteUsers({
                        requesterIdToken: await signinCheckResult.user!.getIdToken(true),
                        uids: [...selection.values()]
                          .map(idx => userListResult[idx].uid)
                          .filter(uid => uid !== signinCheckResult.user!.uid)
                      });
                    } catch (e: any) {
                      alert(e);
                      console.error({ ...e });
                    }

                    userList.refetch();
                  }}
                >
                  Confirm
                </button>
                <button onClick={() => setPromptType(null)}>Cancel</button>
              </div>
            </>
          )}
        </FullscreenPrompt>
      )}
      <div id={styles.toolbar}>
        <h1>User list</h1>
        <div>
          <button onClick={() => setPromptType(PromptType.CREATE_ACCOUNTS)}>Create accounts</button>
          <button onClick={() => setPromptType(PromptType.VERIFY_SELECTED)} disabled={!selection.size}>
            Verify selected
          </button>
          <button onClick={() => setPromptType(PromptType.DELETE_SELECTED)} disabled={!selection.size}>
            Delete selected
          </button>
        </div>
      </div>
      {userList.isSuccess && (
        <SelectableTable
          data={userListResult}
          columns={[
            ["Email", item => item.email],
            [
              "Email verified",
              item => <span>{item.emailVerified ? "Yes" : "No"}</span>,
              { style: { textAlign: "center" } }
            ]
          ]}
          dataKeyField="uid"
          selection={selection}
          setSelection={setSelection}
        />
      )}
    </section>
  );
}
