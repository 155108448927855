import { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";

import { ref, uploadBytes } from "firebase/storage";
import { useSigninCheck, useStorage } from "reactfire";
import { fileExists } from "common/utils/firebase";

import ModelList from "../model-list";
import useBrowserModels from "../use-browser-models";
import LoadingOverlay from "components/loading-overlay";
import styles from "./exporter.module.scss";

type UploadQuery = typeof useMutation<string, unknown, void, unknown>;

const enum State {
  SELECT_SLOT,
  NAME,
  CONFIRM_OVERWRITE
}

export type ExporterProps = {
  generateZip: () => Promise<Blob>;
  onCancel: () => void;
} & Parameters<UploadQuery>[2] &
  (
    | { type: "browser"; storageBucketPath?: never; prefix: string }
    | { type: "remote"; storageBucketPath: string; prefix?: never }
  );

export default function Exporter(props: ExporterProps) {
  const { generateZip, storageBucketPath, ...rest } = props;
  const { data: signinCheckResult } = useSigninCheck();
  const storage = useStorage();

  const [state, setState] = useState(props.type === "browser" ? State.SELECT_SLOT : State.NAME);
  const [name, setName] = useState("");

  const [selectedSlot, setSelectedSlot] = useState(-1);
  const [browserModels, setBrowserModels] = useBrowserModels({ prefix: props.prefix ?? "" });

  const storageRef = useMemo(
    () =>
      signinCheckResult?.signedIn
        ? ref(storage, `users/${signinCheckResult.user.uid}/${props.storageBucketPath}/${name}`)
        : null,
    [name, props.storageBucketPath, signinCheckResult?.signedIn, signinCheckResult?.user?.uid, storage]
  );

  const uploadModel = useMutation(
    ["upload-model"],
    async () => {
      const zip = await props.generateZip();

      if (props.type === "browser") {
        await setBrowserModels(models => {
          return [
            ...models.slice(0, selectedSlot),
            { name: models[selectedSlot]?.name ?? name, lastUpdated: Date.now(), zip },
            ...models.slice(selectedSlot + 1)
          ];
        });
      } else {
        if (!storageRef) {
          throw new Error("Sign in to upload model.");
        }

        await uploadBytes(storageRef, zip, { cacheControl: "public, max-age=21600" });
      }

      return name;
    },
    rest
  );

  const formSubmitHander = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (storageRef && (await fileExists(storageRef)) && state !== State.CONFIRM_OVERWRITE) {
        setState(State.CONFIRM_OVERWRITE);
      } else {
        uploadModel.mutate();
      }
    },
    [state, storageRef, uploadModel]
  );

  const browserSlotSelectHandler = useCallback(
    (idx: number) => {
      setSelectedSlot(idx);
      setState(browserModels[idx] ? State.CONFIRM_OVERWRITE : State.NAME);
    },
    [browserModels]
  );

  const browserSlotDeleteHandler = useCallback(
    async (idx: number) =>
      setBrowserModels(models => {
        return [...models.slice(0, idx), null, ...models.slice(idx + 1)];
      }),
    [setBrowserModels]
  );

  return (
    <form id={styles.main} onSubmit={formSubmitHander}>
      {state === State.SELECT_SLOT ? (
        <>
          <h2>Select a save slot</h2>
          <ModelList
            models={browserModels}
            slotAmount={3}
            onSelect={browserSlotSelectHandler}
            onDelete={browserSlotDeleteHandler}
          />
          <button type="button" className="destructive" onClick={props.onCancel}>
            Cancel
          </button>
        </>
      ) : state === State.NAME ? (
        <>
          <label htmlFor="model-name">Give your model a name:</label>
          <input
            id="model-name"
            className="bordered"
            value={name}
            onChange={e => {
              setName(e.target.value);
              e.target.setCustomValidity(e.target.value.includes("/") ? "Your model name may not include '/'." : "");
            }}
          />
          <div>
            <button type="submit" disabled={!name.length}>
              {(!props.generateZip || uploadModel.isLoading) && (
                <LoadingOverlay type="cover" spinnerProps={{ size: 16, color: "white" }} />
              )}
              <span>{props.type === "browser" ? "Save" : "Upload"}</span>
            </button>
            <button type="button" className="destructive" onClick={() => props.onCancel()}>
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <span>
            {props.type === "browser"
              ? "Are you sure you want to overwrite this slot?"
              : "A model with this name already exists. Overwrite?"}
          </span>
          <div>
            <button type="submit">Yes</button>
            <button
              className="destructive"
              onClick={() => setState(props.type === "browser" ? State.SELECT_SLOT : State.NAME)}
            >
              No
            </button>
          </div>
        </>
      )}
    </form>
  );
}
