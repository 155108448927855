import useResizeObserver from "@react-hook/resize-observer";
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from "react";
import { useImmer } from "use-immer";
import { useSpring, animated } from "react-spring";
import Vector2 from "utils/vector2";
import styles from "./pulse-fx.module.scss";

interface PulseFxProps {
  interval?: number;
  color?: string;
}

export const PulseFx = forwardRef<{ pulse: () => void }, PulseFxProps>((props, ref) => {
  const elRef = useRef<HTMLDivElement>(null);
  const [parentSize, setParentSize] = useImmer(Vector2.zero);
  const style = useSpring({
    from: { width: parentSize.x, height: parentSize.y, opacity: 1 },
    to: { width: parentSize.x + 16, height: parentSize.y + 16, opacity: 0 },
    config: { tension: 100 }
  });

  useResizeObserver(elRef.current?.parentElement ?? null, entry =>
    setParentSize(draft => {
      draft.x = entry.contentRect.width;
      draft.y = entry.contentRect.height;
    })
  );

  const pulse = useCallback(() => {
    for (const key in style) {
      if (style.hasOwnProperty(key)) {
        style[key as keyof typeof style].reset();
      }
    }
  }, [style]);

  useEffect(() => {
    if (props.interval) {
      const interval = setInterval(pulse, props.interval);

      return () => clearInterval(interval);
    }
  }, [props.interval, pulse]);

  useImperativeHandle(ref, () => ({ pulse }));

  return (
    <animated.div
      ref={elRef}
      style={{
        ...style,
        ...(props.color ? { background: props.color } : {}),
        opacity: style.opacity.to(value => (value > 0.75 ? 1 : Math.mapRange(value, 0, 0.75)))
      }}
      className={styles.main}
    />
  );
});

export default PulseFx;