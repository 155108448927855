import { enableMapSet } from "immer";
import React from "react";
import ReactDOM from "react-dom/client";
import { IconContext } from "react-icons";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { AuthProvider, FirebaseAppProvider, FirestoreProvider, StorageProvider } from "reactfire";
import { FIREBASE_CONFIG } from "./utils/firebase";
import { store } from "./utils/store";

import "@tensorflow/tfjs-backend-webgl";
import App from "./App";
import "./ext";
import "./index.scss";

const app = initializeApp(FIREBASE_CONFIG);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

if (process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_CLOUD !== "true") {
  console.log("Using emulators...");
  connectAuthEmulator(auth, `http://localhost:${process.env.REACT_APP_FIREBASE_EMULATOR_AUTH_PORT ?? 9099}`);
  connectFirestoreEmulator(db, "localhost", Number(process.env.REACT_APP_FIREBASE_EMULATOR_FIRESTORE_PORT ?? 8081));
  connectStorageEmulator(storage, "localhost", Number(process.env.REACT_APP_FIREBASE_EMULATOR_STORAGE_PORT ?? 9199));
}

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

enableMapSet();

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseApp={app}>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={db}>
          <StorageProvider sdk={storage}>
            <QueryClientProvider client={queryClient}>
              <BrowserRouter>
                <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
                  <Provider store={store}>
                    <App />
                  </Provider>
                </IconContext.Provider>
              </BrowserRouter>
            </QueryClientProvider>
          </StorageProvider>
        </FirestoreProvider>
      </AuthProvider>
    </FirebaseAppProvider>
  </React.StrictMode>
);
