import { HTMLAttributes, ReactNode } from "react";
import { MoonLoader } from "react-spinners";
import classNames from "classnames";
import type { LoaderSizeProps } from "react-spinners/helpers/props";
import styles from "./loading-overlay.module.scss";

interface BaseLoadingOverlayProps extends HTMLAttributes<HTMLDivElement> {
  type?: "blur" | "cover";
}

type LoadingOverlayProps = BaseLoadingOverlayProps &
  ({ children?: ReactNode; spinnerProps?: never } | { children?: never; spinnerProps?: LoaderSizeProps });

export default function LoadingOverlay(props: LoadingOverlayProps) {
  const { children, className, type, spinnerProps, ...rest } = props;
  return (
    <div
      {...rest}
      className={classNames(styles.main, props.type === "cover" ? styles.cover : styles.blur, props.className)}
    >
      {props.children ?? <MoonLoader {...props.spinnerProps} />}
    </div>
  );
}
