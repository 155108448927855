import React, { useCallback, useEffect, useState } from "react";
import "pages/speech-to-text/SpeechToText.css";
import { useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";

const languages = ["zh-HK", "en-GB", "zh-CN"];
const PAUSE_TIME = 1; //seconds

function TextToSpeech() {
  const [allVoices, setAllVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [filteredVoices, setFilteredVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [selectedVoice, setSelectedVoice] = useState<SpeechSynthesisVoice | null>(null);
  const [userInput, setUserInput] = useState("");
  const [otherVoicesModal, setOtherVoicesModal] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const navigate = useNavigate();

  const speak = useCallback(async () => {
    setIsSpeaking(true);
    const utterance = new window.SpeechSynthesisUtterance();
    (window as any).utterance = utterance;
    utterance.text = userInput;
    utterance.voice = selectedVoice;
    utterance.onend = async () => {
      await new Promise(resolve => setTimeout(resolve, PAUSE_TIME * 1000));
      setIsSpeaking(false);
    };
    utterance.rate = 0.9;
    utterance.pitch = 1;
    utterance.volume = 1.5;
    window.speechSynthesis.speak(utterance);
  }, [selectedVoice, userInput]);

  useEffect(() => {
    if (!filteredVoices.length) {
      const voices = window.speechSynthesis.getVoices();
      setAllVoices(voices);
      setFilteredVoices(voices.filter(i => i.voiceURI.startsWith("Google") && languages.includes(i.lang)));
    } else {
      setSelectedVoice(filteredVoices[0]);
    }
  }, [filteredVoices]);

  return (
    <>
      <section className="page">
        <div className="full-width" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginBottom: 16 }}>
          <div />
          <h3 className="flex-center">Text to Speech</h3>
          <div className="flex-row-end">
            <button onClick={() => navigate("/speech-to-text")}>Switch to speech-to-text</button>
          </div>
        </div>
        <section className="sectionContainer">
          <div className="sectionHeader">請選擇讀語言 / Select language</div>
          <div className="voiceSelect">
            {filteredVoices.map((i, idx) => (
              <button
                key={i.voiceURI + idx}
                className={`voiceChip ${selectedVoice?.voiceURI === i.voiceURI ? "selected" : ""}`}
                onClick={() => setSelectedVoice(i)}
              >
                {i.voiceURI}
              </button>
            ))}
          </div>
          <button
            className="mt-2"
            style={{ width: "min(80%, 640px", background: "#333" }}
            onClick={() => setOtherVoicesModal(true)}
          >
            Other voices (view only)
          </button>
        </section>
        <br />
        <section className="sectionContainer">
          <div className="sectionHeader">請輸入字句 / Please input your sentence</div>
          <div className="my-3 full-width flex-center">
            <textarea
              rows={4}
              style={{ border: "solid 1px #AAA", width: "min(80%, 640px)" }}
              value={userInput}
              onChange={e => setUserInput(e.target.value)}
            />
          </div>
          <div className="w-50" style={{ width: "min(80%, 640px)" }}>
            <button onClick={speak} disabled={!userInput || isSpeaking} className="full-width">
              {selectedVoice && selectedVoice.lang === "en-GB" ? "SPEECH" : "開始說話"}
            </button>
          </div>
        </section>
      </section>
      <section className={`allVoicesModal full-size ${otherVoicesModal ? "open" : ""}`}>
        <div className="voiceModal">
          <div className="full-width p-3 flex-center" style={{ color: "#FFF" }}>
            List of voices in this browser
          </div>
          {allVoices.map((i, idx) => (
            <button key={i.voiceURI + idx} className="voiceChip">{`${i.voiceURI} - ${i.lang}`}</button>
          ))}
          <div className="flex-center modalClose" onClick={() => setOtherVoicesModal(false)}>
            <MdClose size={48} color={"#555"} />
          </div>
        </div>
      </section>
    </>
  );
}

export default TextToSpeech;
