import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GlobalState {
  userIsAuth: boolean;
  uid: string;
  isLoading: boolean;
  message: string;
  onConfirm: (() => void) | null;
}

const initialState: GlobalState = {
  userIsAuth: false,
  uid: "",
  isLoading: false,
  message: "",
  onConfirm: null
};
export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setIsAuth: (state: GlobalState, action: PayloadAction<{ isLoggedIn: boolean; uid: string }>) => {
      state.userIsAuth = action.payload.isLoggedIn;
      state.uid = action.payload.uid;
    },
    setIsLoading: (state: GlobalState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setMessage: (state: GlobalState, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setOnConfirm: (state: GlobalState, action: PayloadAction<(() => void) | null>) => {
      state.onConfirm = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setIsAuth, setIsLoading, setMessage, setOnConfirm } = globalSlice.actions;

export default globalSlice.reducer;
