import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, ElementRef } from "react";
import useLoop from "hooks/use-loop";
import PulseFx from "components/pulse-fx";
import styles from "./data-key-input.module.scss";

interface DataKeyInputProps {
  onDataKeyChange: (dataKey: string) => void;
  loopFn?: (dataKey: string) => void;
  indicatorPulseInterval?: number;
}

export const DataKeyInput = forwardRef<{ pulseIndicator: () => void }, DataKeyInputProps>((props, ref) => {
  const [enabled, setEnabled] = useState(false);
  const [dataKey, setDataKey] = useState("");
  const pulseFxRef = useRef<ElementRef<typeof PulseFx>>(null);

  const pulseIndicator = useCallback(() => pulseFxRef.current?.pulse(), []);

  useImperativeHandle(ref, () => ({ pulseIndicator }));

  useLoop(
    enabled && !!props.loopFn,
    () => {
      props.loopFn?.(dataKey);
    },
    1000
  );

  useEffect(() => {
    props.onDataKeyChange(enabled ? dataKey : "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  return (
    <div className={styles.main}>
      <div aria-disabled={!enabled}>
        {enabled && <PulseFx ref={pulseFxRef} color="#0DFF34" interval={props.indicatorPulseInterval} />}
      </div>
      <input placeholder="Data key" onChange={e => setDataKey(e.target.value)} disabled={enabled} />
      <button onClick={() => setEnabled(!enabled)} aria-pressed={enabled} disabled={!dataKey.length}>
        {enabled ? "Stop" : "Start"}
      </button>
    </div>
  );
});

export default DataKeyInput;
