import Vector2 from "./vector2";

/**
 * Adds a delay to your code using the Promise API.
 * @param ms Milliseconds to delay
 */
export function delay(ms: number) {
  return new Promise<void>(res => setTimeout(res, ms));
}

/**
 * Returns a promise that is resolved when a click that is outside of the watched elements is detected.
 * @param  elements The element or array of elements to be watched.
 * @return          A promise that is resolved when a click that is outside of the watched elements is detected.
 */
export async function clickedOutside(elements: Element | Element[]) {
  return new Promise<void>(res => {
    const handleClick = (e: MouseEvent) => {
      if (
        (Array.isArray(elements) && elements.every(element => !element.contains(e.target as Node))) ||
        (!Array.isArray(elements) && !elements.contains(e.target as Node))
      ) {
        document.removeEventListener("click", handleClick);
        res();
      }
    };

    document.addEventListener("click", handleClick);
  });
}

/**
 * Executes the callback when a click that is outside of the watched elements is detected.
 * @param  elements The element or array of elements to be watched.
 * @param  callback The callback to be executed when a click that is outside of the watched elements is detected.
 * @return          A function that can be called to stop watching for clicks outside of the watched elements.
 */
export function onClickedOutside(elements: Element | Element[], callback: () => void) {
  const handleClick = (e: MouseEvent) => {
    if (
      (Array.isArray(elements) && elements.every(element => !element.contains(e.target as Node))) ||
      (!Array.isArray(elements) && !elements.contains(e.target as Node))
    ) {
      callback();
    }
  };

  document.addEventListener("click", handleClick);

  return () => document.removeEventListener("click", handleClick);
}

/**
 * Downloads the specified blob.
 * @param blob The blob to be downloaded.
 * @param name The name of the file to be downloaded.
 */
export function triggerDownload(blob: Blob, name: string): void;
/**
 * Downloads the specified file.
 * @param url  The url of the file to be downloaded.
 * @param name The name of the file to be downloaded.
 */
export function triggerDownload(url: string, name: string): void;
export function triggerDownload(blob: Blob | string, name: string) {
  const url = typeof blob === "string" ? blob : URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = name;
  a.click();
  URL.revokeObjectURL(url);
  a.remove();
}

/**
 * Returns the distance between the document-relative position and the closest edge of the element.
 * @param  position The document-relative position.
 * @param  element  The element to be checked.
 */
export function distanceToElement(position: Vector2, element: HTMLElement) {
  const rect = element.getBoundingClientRect(),
    ny1 = rect.top + document.body.scrollTop,
    ny2 = ny1 + element.offsetHeight,
    nx1 = rect.left + document.body.scrollLeft,
    nx2 = nx1 + element.offsetWidth,
    maxX1 = Math.max(position.x, nx1),
    minX2 = Math.min(position.x, nx2),
    maxY1 = Math.max(position.y, ny1),
    minY2 = Math.min(position.y, ny2),
    to = {
      x: minX2 >= maxX1 ? position.x : nx2 < position.x ? nx2 : nx1,
      y: minY2 >= maxY1 ? position.y : ny2 < position.y ? ny2 : ny1
    };

  return ((to.x - position.x) ** 2 + (to.y - position.y) ** 2) ** 0.5;
}

/**
 * Finds all elements in the entire page matching `selector`, even if they are in shadowRoots.
 * Just like `querySelectorAll`, but automatically expand on all child `shadowRoot` elements.
 * @see https://stackoverflow.com/a/71692555/2228771
 */
export function querySelectorAllShadows(selector: string, el: HTMLElement | ShadowRoot = document.body): Element[] {
  // recurse on childShadows
  const childShadows = Array.from(el.querySelectorAll("*"))
    .map(el => el.shadowRoot)
    .filter(Boolean) as ShadowRoot[];

  // console.log('[querySelectorAllShadows]', selector, el, `(${childShadows.length} shadowRoots)`);

  const childResults = childShadows.map(child => querySelectorAllShadows(selector, child));

  // fuse all results into singular, flat array
  const result = Array.from(el.querySelectorAll(selector));
  return result.concat(childResults.flat()).flat();
}
