import { DocumentData, QueryDocumentSnapshot, SnapshotOptions } from "firebase/firestore";
import type { Fields, DataDoc } from "common/types/graph";

export default class Data {
  data: DataDoc["data"];
  lastUpdated: DataDoc["lastUpdated"];
  private _validFields = Array<Fields>();
  get validFields() {
    return this._validFields;
  }

  constructor(doc: DataDoc) {
    this.data = doc.data;
    this.lastUpdated = doc.lastUpdated;

    for (const field of ["field1", "field2", "field3", "field4", "field5", "field6"] as const) {
      if (
        !doc.data.map(datapoint => ({ value: datapoint[field], timestamp: datapoint.timestamp })).every(v => !v.value)
      ) {
        this._validFields.push(field);
      }
    }
  }

  static converter = {
    toFirestore(data: Data): DocumentData {
      throw new Error("Method not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): Data {
      return new Data(snapshot.data(options) as DataDoc);
    }
  };
}
