import { useCallback, useState } from "react";
import { BsArrowCounterclockwise } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import "./SpeechToText.css";

type Lang = "zh-yue" | "en-GB" | "zh-CN";

function SpeechToText() {
  const [text, setText] = useState("");
  const [lang, setLang] = useState<Lang>("zh-yue");
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const navigate = useNavigate();

  const handleChangeLang = useCallback((language: Lang) => {
    setLang(language);
    setText("");
  }, []);

  const handleStartListen = useCallback(() => {
    resetTranscript();
    SpeechRecognition.startListening({ language: lang, continuous: true });
  }, [lang, resetTranscript]);

  const handleFinish = useCallback(() => {
    setText(transcript);
    SpeechRecognition.stopListening();
    //do sth with text
    resetTranscript();
  }, [resetTranscript, transcript]);

  const handleRestart = useCallback(() => {
    setText("");
    handleStartListen();
  }, [handleStartListen]);

  return browserSupportsSpeechRecognition ? (
    <>
      <section className="page">
        <div className="full-width" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", marginBottom: 16 }}>
          <div />
          <h3 className="flex-center">Speech to Text</h3>
          <div className="flex-row-end">
            <button onClick={() => navigate("/text-to-speech")}>Switch to text-to-speech</button>
          </div>
        </div>
        <section className="sectionContainer">
          <div className="sectionHeader">請選擇偵測的語言 / Select language</div>
          <div className="langSelect">
            <button
              className={`button ${lang === "zh-yue" ? "active" : ""}`}
              onClick={() => handleChangeLang("zh-yue")}
            >
              廣東話
            </button>
            <button className={`button ${lang === "en-GB" ? "active" : ""}`} onClick={() => handleChangeLang("en-GB")}>
              English
            </button>
            <button className={`button ${lang === "zh-CN" ? "active" : ""}`} onClick={() => handleChangeLang("zh-CN")}>
              普通話
            </button>
          </div>
        </section>
        <br />
        <section className="sectionContainer">
          {!!text && (
            <>
              <b>{lang === "en-GB" ? "Your speech" : "你的說話"}</b>
              <br />
              {!!text && <p>{text}</p>}
              <br />
            </>
          )}
          {!listening && !text && (
            <button className="startButton" onClick={handleStartListen}>
              {lang === "en-GB" ? "Start listening" : "開始聆聽"}
            </button>
          )}
          {!listening && !!text && (
            <button
              className="startButton"
              onClick={() => {
                handleRestart();
              }}
            >
              <BsArrowCounterclockwise color="#FFF" size={24} />
              <div className="retry">{lang === "en-GB" ? "Retry" : "再試"}</div>
            </button>
          )}
        </section>
      </section>
      {listening && <VoiceModal lang={lang} onFinish={handleFinish} />}
    </>
  ) : (
    <section className="full-size">
      Apologies, your browser does not support speech recognition, please use Google Chrome instead
    </section>
  );
}
export default SpeechToText;

interface VoiceModalProps {
  lang: string;
  onFinish: () => void;
}

export const VoiceModal = (props: VoiceModalProps) => {
  return (
    <section className="modalBackground">
      <div className="modal">
        <div style={{ fontSize: "min(2.4vw, 24px)" }}>{props.lang === "en-GB" ? "Listening..." : "聆聽中..."}</div>
        <button className="stopButton" onClick={props.onFinish}>
          {props.lang === "en-GB" ? "Stop listening" : "停止"}
        </button>
      </div>
    </section>
  );
};
