import { IconBase, IconBaseProps } from "react-icons";

export const MicroBitIcon = (props: IconBaseProps) => {
  return (
    <IconBase {...props} viewBox="0 0 32 32" strokeWidth={1}>
      {/* <svg {...props} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <g clipPath="url(#clip0_126_78)">
        <path d="M9.14267 6.8573C6.71875 6.86012 4.39491 7.82427 2.68094 9.53824C0.966972 11.2522 0.00282303 13.576 0 16C0.0024708 18.424 0.966507 20.748 2.68055 22.4621C4.3946 24.1761 6.71864 25.1402 9.14267 25.1426H22.8587C25.2826 25.1402 27.6065 24.1761 29.3203 22.462C31.0341 20.7479 31.9979 18.4239 32 16C31.9975 13.5762 31.0337 11.2523 29.3199 9.53832C27.6062 7.82432 25.2825 6.86012 22.8587 6.8573H9.14267ZM9.14267 10.516H22.8587C24.3127 10.5174 25.7067 11.0956 26.7349 12.1238C27.763 13.1519 28.3413 14.546 28.3427 16C28.3416 17.4545 27.7636 18.8491 26.7355 19.878C25.7074 20.9068 24.3132 21.4858 22.8587 21.488H9.14267C7.68805 21.4858 6.29366 20.9069 5.26535 19.8781C4.23703 18.8493 3.65874 17.4546 3.65733 16C3.6591 14.5458 4.23763 13.1518 5.26598 12.1237C6.29434 11.0956 7.68853 10.5174 9.14267 10.516V10.516ZM22.7 14.1546C22.4575 14.1549 22.2174 14.2029 21.9935 14.296C21.7695 14.389 21.5661 14.5253 21.3948 14.6969C21.2235 14.8686 21.0877 15.0723 20.9951 15.2965C20.9026 15.5206 20.8551 15.7608 20.8553 16.0033C20.8556 16.2458 20.9036 16.4859 20.9967 16.7098C21.0897 16.9338 21.226 17.1372 21.3976 17.3085C21.5693 17.4798 21.773 17.6156 21.9972 17.7082C22.2213 17.8007 22.4615 17.8482 22.704 17.848C23.1938 17.8474 23.6633 17.6524 24.0092 17.3057C24.3551 16.959 24.5492 16.4891 24.5487 15.9993C24.5481 15.5095 24.3531 15.04 24.0064 14.6941C23.6597 14.3482 23.1898 14.1541 22.7 14.1546V14.1546ZM9.14533 14.1546C8.65539 14.1541 8.1853 14.3482 7.83849 14.6943C7.49167 15.0404 7.29653 15.51 7.296 16C7.29547 16.4899 7.48959 16.96 7.83566 17.3068C8.18172 17.6536 8.65139 17.8488 9.14133 17.8493C9.38384 17.8496 9.62403 17.8021 9.84817 17.7095C10.0723 17.6169 10.276 17.4811 10.4477 17.3098C10.6194 17.1385 10.7556 16.9351 10.8487 16.7112C10.9417 16.4872 10.9897 16.2471 10.99 16.0046C10.9903 15.7621 10.9428 15.5219 10.8502 15.2978C10.7576 15.0736 10.6218 14.8699 10.4505 14.6983C10.2792 14.5266 10.0758 14.3903 9.85187 14.2973C9.62792 14.2043 9.38784 14.1562 9.14533 14.156V14.1546Z"/>
      </g>
      <defs>
        <clipPath id="clip0_126_78">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
      {/* </svg> */}
    </IconBase>
  );
};
