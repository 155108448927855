import React from "react";
import classNames from "classnames";
import styles from "./widget.module.scss";

export interface WidgetProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  titleElement: React.ReactNode;
  contentContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  color?: string;
}

export default function Widget(props: WidgetProps) {
  props = Object.assign({ color: "#333" }, props);
  const { children, titleElement, contentContainerProps, ...rest } = props;

  return (
    <section {...rest} className={classNames(styles.main, props.className)}>
      <div style={{ background: props.color }}>{props.titleElement}</div>

      <div {...contentContainerProps}>{props.children}</div>
    </section>
  );
}
