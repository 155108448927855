import axios from "axios";
import type {
  BodyOf,
  CreateAccountsRequest,
  DeleteUsersRequest,
  DeleteUsersResponse,
  ForceVerifyUserEmailRequest,
  GetAllUsersRequest,
  GetAllUsersResponse,
  SetAdminRoleRequest
} from "common/types/api";

axios.defaults.baseURL =
  process.env.NODE_ENV === "development" && process.env.REACT_APP_USE_CLOUD !== "true"
    ? `http://localhost:${process.env.REACT_APP_FIREBASE_EMULATOR_FUNCTIONS_PORT ?? 5001}/musedata-53fd6/asia-east2/api`
    : "https://asia-east2-musedata-53fd6.cloudfunctions.net/api";

axios.defaults.headers.get["Content-Type"] = axios.defaults.headers.post["Content-Type"] =
  "application/json; charset=utf-8";

export async function sendMqtt(topic: string, message: string) {
  return await axios.post("/send-mqtt", { topic, message });
}

export async function getAllUsers(body: BodyOf<GetAllUsersRequest>) {
  return await axios.post<BodyOf<GetAllUsersResponse>>("/get-all-users", body);
}

export async function createAccounts(body: BodyOf<CreateAccountsRequest>) {
  return await axios.post("/create-accounts", body);
}

export async function forceVerifyUserEmail(body: BodyOf<ForceVerifyUserEmailRequest>) {
  return await axios.post("/force-verify-user-email", body);
}

export async function updateAdminRoleStaff(body: BodyOf<SetAdminRoleRequest>) {
  return await axios.post("/set-admin-role-staff", body);
}

export async function deleteUsers(body: BodyOf<DeleteUsersRequest>) {
  return await axios.post<BodyOf<DeleteUsersResponse>>("/delete-users", body);
}
