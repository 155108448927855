import { useEffect, useMemo, useRef, useState, ElementRef, useCallback, RefObject } from "react";
import { useQuery } from "react-query";
import Webcam from "react-webcam";
import {
  AiOutlineCloseCircle,
  AiOutlineCloudServer,
  AiOutlineDesktop,
  AiOutlineDownload,
  AiOutlineQuestionCircle,
  AiOutlineUpload
} from "react-icons/ai";
import { BiServer } from "react-icons/bi";
import { TbBrowser } from "react-icons/tb";
import { useImmer } from "use-immer";

import { getMetadata, listAll, ref } from "firebase/storage";
import { useSigninCheck, useStorage } from "reactfire";
import * as tf from "@tensorflow/tfjs";

import Importer from "./model-managers/importer";
import Exporter from "./model-managers/exporter";

import useLoop from "hooks/use-loop";
import useNotification from "hooks/use-notification";

import Widget from "components/widget";
import DataKeyInput from "components/data-key-input";
import FullscreenPrompt from "components/fullscreen-prompt";
import LoadingOverlay from "components/loading-overlay";
import FloatingPanel, { FloatingPanelHook } from "components/floating-panel";
import { MicroBitIcon } from "components/icons";

import _ from "lodash";
import classNames from "classnames";
import JSZip from "jszip";
import { nanoid } from "nanoid";
import ms from "ms";

import { sendMqtt } from "api";
import {
  microBitConnect,
  microBitDisconnect,
  MicroBitEventCallbackHandler,
  microBitSend
} from "utils/micro-bit-interface";
import { onClickedOutside, triggerDownload } from "utils";
import type { MaybePromise } from "common/types";

import uartInstruction from "./assets/uart_instruction.png";
import mqttInstruction from "./assets/mqtt_instruction.png";
import styles from "./template.module.scss";
import useResizeObserver from "@react-hook/resize-observer";

export type ClassData = { name: string; imgs: string[] };

export type TrainingRequestHandler = (
  classes: Map<string, ClassData>,
  onProgress: (value: number, done: boolean) => void
) => void;

export type PredictionRequestHandler = (videoEl: HTMLVideoElement) => Promise<Map<string, number>>;

export type ModelExportHandler = (classes: Map<string, ClassData>, jszip: JSZip) => MaybePromise<JSZip>;

export type ModelImportHandler = (zipFile: JSZip) => Promise<Map<string, ClassData>>;

const enum PromptType {
  UART_INSTRUCTION = 1,
  MQTT_INSTRUCTION,
  SELECT_MODEL_REMOTE,
  SELECT_MODEL_BROWSER,
  SAVE_MODEL_BROWSER,
  SAVE_MODEL_REMOTE
}

const enum ModelState {
  CAPTURE = "Capture",
  TRAINING = "Training",
  EVALUATE = "Evaluate"
}

const enum ModelSource {
  UNSAVED,
  LOCAL,
  BROWSER,
  REMOTE
}

enum ExportPanelOptions {
  MODEL = "Model",
  DATASET = "Dataset",
  BOTH = "Both"
}

interface TemplateProps {
  storageBucketPath: string;
  prefix: string;
  onTrainingRequested: TrainingRequestHandler;
  onPredictionRequested: PredictionRequestHandler;
  onModelExport: ModelExportHandler;
  onModelImport: ModelImportHandler;
  canvasRef?: RefObject<HTMLCanvasElement>;
  onPredictionRequestStreamStop?: () => void;
}

export default function Template(props: TemplateProps) {
  const { data: signinCheckResult } = useSigninCheck();
  const storage = useStorage();
  const notification = useNotification({ duration: 10000 });

  const dataKeyInputRef = useRef<ElementRef<typeof DataKeyInput>>(null);
  const webcamRef = useRef<Webcam>(null);
  const importPanelRef = useRef<FloatingPanelHook>(null);
  const exportPanelRef = useRef<FloatingPanelHook>(null);
  const savePanelRef = useRef<FloatingPanelHook>(null);
  const uploadRef = useRef<HTMLInputElement>(null);
  const classContainerRef = useRef<HTMLDivElement>(null);
  const captureBtnRef = useRef<HTMLButtonElement>(null);

  const [promptType, setPromptType] = useState<PromptType | null>(null);

  const [modelState, setModelState] = useState(ModelState.CAPTURE);
  const [modelSource, setModelSource] = useState(ModelSource.UNSAVED);
  const remoteModelBlob = useRef<Blob | null>(null);
  const remoteModelList = useQuery(
    ["remote-model-list"],
    async () => {
      if (!signinCheckResult.signedIn) {
        throw new Error("Sign in to access remote models.");
      }

      return await Promise.all(
        (
          await listAll(ref(storage, `users/${signinCheckResult.user.uid}/${props.storageBucketPath}`))
        ).items.map(async ref => await getMetadata(ref))
      );
    },
    { enabled: false, retry: false, staleTime: ms("1h") }
  );

  const [classes, setClasses] = useImmer(new Map<string, ClassData>());
  const [selectedClass, setSelectedClass] = useState<(ClassData & { id: string }) | null>(null);

  const [isCapturing, setIsCapturing] = useState(false);
  const [trainingProgress, setTrainingProgress] = useState<boolean | number>(false);
  const [predictions, setPredictions] = useImmer(new Map<string, number>());
  const topPrediction = useMemo(() => _.maxBy([...predictions.entries()], ([, p]) => p)?.[0], [predictions]);

  const [isExporting, setIsExporting] = useState(false);

  const [microBit, setMicroBit] = useState<USBDevice | null>(null);
  const [dataKey, setDataKey] = useState("");

  const microBitEventHandler: MicroBitEventCallbackHandler = useCallback((event, device, data) => {
    switch (event) {
      case "CONNECTED":
        setMicroBit(device);
        notification.push({ type: "success", body: "Micro:bit connected successfully!" });
        break;

      case "DISCONNECTED":
        setMicroBit(null);
        notification.push({ body: "Micro:bit disconnected." });
        break;

      case "CONNECTION_FAILURE":
        notification.push({
          type: "error",
          title: "Failed to connect to micro:bit.",
          body: data instanceof Error ? `An error occurred: ${data.message}` : "An unknown error occurred."
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const datasetExportHandler = useCallback(() => {
    const zip = new JSZip();

    for (const [id, { name, imgs }] of [...classes.entries()]) {
      zip.file(`dataset/${id}/name.txt`, name);

      for (let i = 0; i < imgs.length; i++) {
        zip.file(`dataset/${id}/${i}.webp`, imgs[i].split("base64,")[1], { base64: true });
      }
    }

    return zip;
  }, [classes]);

  const importHandler = useCallback(
    async (file: Blob) => {
      let zip: JSZip;

      try {
        zip = await JSZip.loadAsync(file);
      } catch (e) {
        notification.push({
          type: "error",
          title: "Failed to import.",
          body: "An error occurred while trying to open the zip file."
        });

        return;
      }

      let modelImported = true,
        datasetImported = true;

      try {
        setClasses(await props.onModelImport(zip));
      } catch (e) {
        console.error(e);
        modelImported = false;
      }

      try {
        const dataset = zip.folder("dataset");

        if (dataset) {
          const map = new Map<string, ClassData>();
          const ids = Object.values(dataset.files)
            .filter(file => file.dir && !file.name.endsWith("dataset/"))
            .map(dir => dir.name.match(/dataset\/(.*)\//)?.[1]!);

          for (const id of ids) {
            const folder = dataset.folder(id)!;
            map.set(id, {
              name: (await folder.file("name.txt")?.async("string")) ?? id,
              imgs: await Promise.all(
                Object.values(folder.files)
                  .filter(file => file.name.match(new RegExp(`dataset/${id}/.*\\.webp`, "g")))
                  .map(async file => `data:image/webp;base64,${await file.async("base64")}`)
              )
            });
          }

          setClasses(map);
        } else {
          throw new Error("Dataset folder not found.");
        }
      } catch (e) {
        console.error(e);
        datasetImported = false;
      }

      if (!modelImported && !datasetImported) {
        notification.push({
          type: "error",
          title: "Failed to import.",
          body: "The zip file does not contain valid data."
        });

        return;
      }

      setTrainingProgress(true);
      notification.push({
        type: "success",
        title: "Model imported successfully!"
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onModelImport, setClasses]
  );

  const exportOptionSelectHandler = useCallback(
    async (option: ExportPanelOptions) => {
      performance.mark("export");
      let zip: JSZip;
      let name: string;

      if (modelSource === ModelSource.REMOTE) {
        if (!remoteModelBlob.current) {
          throw new Error("Remote model blob is not set.");
        }

        zip = await JSZip.loadAsync(remoteModelBlob.current);
      } else {
        zip = new JSZip();
      }

      switch (option) {
        case ExportPanelOptions.MODEL:
          name = "model";

          if (modelSource === ModelSource.REMOTE) {
            zip.remove("dataset");
          } else {
            zip = await props.onModelExport(classes, zip);
          }

          break;

        case ExportPanelOptions.DATASET:
          name = "dataset";
          zip = datasetExportHandler();
          break;

        case ExportPanelOptions.BOTH:
          name = "model-dataset";

          if (modelSource !== ModelSource.REMOTE) {
            zip = await props.onModelExport(classes, datasetExportHandler());
          }

          break;
      }

      const result = {
        zip: await zip.generateAsync({ type: "blob" }),
        name: `${props.prefix}-${name}-export-${Date.now()}.zip`
      };

      console.log(`Exported in ${performance.measure("measure-export", "export").duration.toFixed(1)} ms`);
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes, datasetExportHandler, props.prefix, props.onModelExport]
  );

  const updateRemoteModelList = useCallback(() => {
    if (remoteModelList.isStale || remoteModelList.isIdle) {
      remoteModelList.refetch();
    }
  }, [remoteModelList]);

  useEffect(() => {
    const fn = async () => {
      try {
        if (!(await tf.setBackend("webgl"))) {
          notification.push({
            type: "warning",
            title: "WebGL not supported.",
            body: "Falling back to CPU backend. You may experience performance problems.",
            id: "webgl-not-supported"
          });

          await tf.setBackend("cpu");
        }

        await tf.ready();
      } catch (e) {
        console.error({ e });
      }
    };

    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModelState(
      typeof trainingProgress === "number"
        ? ModelState.TRAINING
        : trainingProgress
        ? ModelState.EVALUATE
        : ModelState.CAPTURE
    );
  }, [trainingProgress]);

  // Handles class selection
  useEffect(() => {
    if (!isCapturing && classContainerRef.current && captureBtnRef.current) {
      return onClickedOutside(
        [...classContainerRef.current.children].filter(el => el.tagName === "ARTICLE").concat(captureBtnRef.current),
        () => setSelectedClass(null)
      );
    }
  }, [classes, isCapturing]);

  useResizeObserver(webcamRef.current?.video ?? null, entry => {
    if (props.canvasRef?.current) {
      props.canvasRef.current.width = entry.contentRect.width;
      props.canvasRef.current.height = entry.contentRect.height;
    }
  });

  // Capture webcam to dataset
  useLoop(
    isCapturing && modelState === ModelState.CAPTURE,
    useCallback(() => {
      const img = webcamRef.current!.getScreenshot();

      if (img) {
        setClasses(draft => {
          draft.get(selectedClass!.id)!.imgs.push(img);
        });
      }
    }, [selectedClass, setClasses]),
    100
  );

  // Capture webcam for prediction
  useLoop(
    isCapturing && modelState === ModelState.EVALUATE,
    useCallback(async () => {
      if (webcamRef.current?.video) {
        setPredictions(await props.onPredictionRequested(webcamRef.current!.video));
        return () => {
          setPredictions(new Map());
          props.onPredictionRequestStreamStop?.();
        };
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.onPredictionRequested, setPredictions]),
    100
  );

  // Send prediction to micro:bit via serial
  useLoop(
    !!microBit && !!topPrediction,
    useCallback(
      () => microBitSend(microBit!, classes.get(topPrediction!)?.name ?? "Unlabelled"),
      [classes, microBit, topPrediction]
    ),
    1000
  );

  // Send prediction to MQTT broker
  useLoop(
    !!dataKey.length && !!topPrediction,
    useCallback(
      () => sendMqtt(dataKey, classes.get(topPrediction!)!.name).then(() => dataKeyInputRef.current?.pulseIndicator()),
      [classes, dataKey, topPrediction]
    ),
    1000
  );

  return (
    <Widget
      id={styles.widget}
      titleElement={
        <>
          {modelState}
          <div id={styles.toolbar}>
            <div>
              <button className="no-style" onClick={() => setPromptType(PromptType.UART_INSTRUCTION)}>
                <AiOutlineQuestionCircle size="1.25em" />
              </button>
              <button
                aria-pressed={!microBit}
                title={microBit ? "Disconnect from micro:bit" : "Connect to micro:bit"}
                onClick={() => (microBit ? microBitDisconnect(microBit) : microBitConnect(microBitEventHandler))}
              >
                <MicroBitIcon size="1.5em" />
              </button>
              <label>UART</label>
            </div>
            <div />
            <div>
              <button className="no-style" onClick={() => setPromptType(PromptType.MQTT_INSTRUCTION)}>
                <AiOutlineQuestionCircle size="1.25em" />
              </button>
              <DataKeyInput ref={dataKeyInputRef} onDataKeyChange={dataKey => setDataKey(dataKey)} />
            </div>
          </div>
        </>
      }
      contentContainerProps={{
        id: styles.main,
        className: classNames(modelState === ModelState.TRAINING && styles.blur)
      }}
    >
      {promptType && (
        <FullscreenPrompt onClickOutside={() => setPromptType(null)}>
          {promptType === PromptType.UART_INSTRUCTION ? (
            <>
              <p>You can read the model's prediction via USB using this code snippet:</p>
              <img src={uartInstruction} alt="micro:bit UART instruction" />
              <p>Note: Make sure to disconnect from the micro: bit editor!</p>
              <button onClick={() => setPromptType(null)}>Okay</button>
            </>
          ) : promptType === PromptType.MQTT_INSTRUCTION ? (
            <>
              <p>You can read the model's prediction via MQTT using this code snippet:</p>
              <img src={mqttInstruction} alt="micro:bit MQTT instruction" />
              <button onClick={() => setPromptType(null)}>Okay</button>
            </>
          ) : [PromptType.SELECT_MODEL_REMOTE, PromptType.SELECT_MODEL_BROWSER].includes(promptType) ? (
            <Importer
              {...(promptType === PromptType.SELECT_MODEL_BROWSER
                ? { type: "browser", prefix: props.prefix }
                : { type: "remote", storageBucketPath: props.storageBucketPath })}
              onCancel={() => setPromptType(null)}
              onSuccess={async zip => {
                await importHandler(zip);
                remoteModelBlob.current = zip;
                setModelSource(ModelSource.REMOTE);
                setPromptType(null);
              }}
              onError={err => {
                console.error({ err });
                setPromptType(null);
                notification.push({
                  type: "error",
                  title: "Failed to import model.",
                  body: "An unknown error occurred."
                });
              }}
            />
          ) : (
            <Exporter
              {...(promptType === PromptType.SAVE_MODEL_BROWSER
                ? { type: "browser", prefix: props.prefix }
                : { type: "remote", storageBucketPath: props.storageBucketPath })}
              generateZip={async () => (await exportOptionSelectHandler(ExportPanelOptions.BOTH)).zip}
              onCancel={() => setPromptType(null)}
              onSuccess={name => {
                setPromptType(null);
                setModelSource(ModelSource.REMOTE);
                notification.push({
                  type: "success",
                  title: "Upload successful",
                  body: `${name} is now saved ${
                    promptType === PromptType.SAVE_MODEL_BROWSER ? "in your browser" : "remotely"
                  }.`
                });
              }}
              onError={err => {
                console.error({ err });
                setPromptType(null);
                notification.push({
                  type: "error",
                  title: "Upload failed",
                  body: "An unknown error occurred."
                });
              }}
            />
          )}
        </FullscreenPrompt>
      )}
      <FloatingPanel ref={importPanelRef}>
        <ul>
          <li
            role="button"
            onClick={() => {
              setPromptType(PromptType.SELECT_MODEL_BROWSER);
              importPanelRef.current?.disable(true);
            }}
          >
            <span>
              <TbBrowser /> From browser
            </span>
          </li>
          <li
            role="button"
            onClick={() => {
              uploadRef.current?.click();
              importPanelRef.current?.disable(true);
            }}
            aria-disabled={!signinCheckResult?.user?.emailVerified || modelSource === ModelSource.LOCAL}
          >
            <span>
              <AiOutlineDesktop /> From computer
            </span>
          </li>
          <li
            role="button"
            onClick={() => {
              setPromptType(PromptType.SELECT_MODEL_REMOTE);
              updateRemoteModelList();
              importPanelRef.current?.disable(true);
            }}
            aria-disabled={!signinCheckResult?.user?.emailVerified || modelSource === ModelSource.REMOTE}
          >
            <span>
              <AiOutlineCloudServer /> From server
            </span>
          </li>
        </ul>
      </FloatingPanel>
      <FloatingPanel ref={exportPanelRef}>
        <ul>
          {Object.values(ExportPanelOptions).map(option => (
            <li
              key={option}
              role="button"
              onClick={() => {
                setIsExporting(true);
                exportOptionSelectHandler(option).then(({ zip, name }) => {
                  triggerDownload(zip, name);
                  setIsExporting(false);
                });
                exportPanelRef.current?.disable(true);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      </FloatingPanel>
      <FloatingPanel ref={savePanelRef}>
        <ul>
          <li
            role="button"
            onClick={() => {
              setPromptType(PromptType.SAVE_MODEL_BROWSER);
              savePanelRef.current?.disable(true);
            }}
          >
            <span>
              <TbBrowser /> Browser
            </span>
          </li>
          <li
            role="button"
            onClick={() => {
              setPromptType(PromptType.SAVE_MODEL_REMOTE);
              savePanelRef.current?.disable(true);
            }}
            aria-disabled={!signinCheckResult?.user?.emailVerified}
          >
            <span>
              <BiServer /> Server
            </span>
          </li>
        </ul>
      </FloatingPanel>
      {notification.modal}
      {modelState === ModelState.TRAINING && (
        <LoadingOverlay id={styles["progress-overlay"]}>
          <label>
            <big>Training model...</big>
          </label>
          <progress value={typeof trainingProgress === "number" ? trainingProgress : 0} max={1} />
        </LoadingOverlay>
      )}
      <div id={styles["webcam-container"]}>
        <div id={styles.webcam}>
          {props.canvasRef && <canvas ref={props.canvasRef} />}
          <Webcam ref={webcamRef} />
        </div>
        <div>
          <button
            ref={captureBtnRef}
            onClick={() => setIsCapturing(!isCapturing)}
            disabled={!selectedClass && modelState !== ModelState.EVALUATE}
            aria-pressed={isCapturing}
          >
            {modelState === ModelState.EVALUATE ? (
              isCapturing ? (
                "Stop running model"
              ) : (
                "Run model"
              )
            ) : selectedClass ? (
              isCapturing ? (
                "Stop capturing images"
              ) : (
                <span>
                  Capture images for{" "}
                  {classes.get(selectedClass.id)?.name.length ? classes.get(selectedClass.id)?.name : "selected class"}
                </span>
              )
            ) : (
              "Select a class to begin"
            )}
          </button>
          {modelState === ModelState.CAPTURE && (
            <>
              <button onClick={e => importPanelRef.current?.enable(e)}>
                <AiOutlineUpload />
                <span>Load model/dataset</span>
              </button>
              <input
                ref={uploadRef}
                type="file"
                accept=".zip"
                onChange={e => {
                  if (e.target.files) {
                    performance.mark("import");
                    importHandler(e.target.files[0]).then(() => {
                      setModelSource(ModelSource.LOCAL);
                      console.log(
                        `Imported in ${performance.measure("measure-import", "import").duration.toFixed(1)} ms`
                      );
                      performance.clearMarks("import");
                    });
                    e.target.value = "";
                  }
                }}
                hidden
              />
            </>
          )}
          {modelState === ModelState.EVALUATE && (
            <>
              <button
                onClick={() => {
                  setIsCapturing(false);
                  setTrainingProgress(false);
                }}
              >
                Update training data
              </button>
              <button
                id={styles.export}
                onClick={exportPanelRef.current?.enable}
                disabled={!signinCheckResult.user?.emailVerified || isExporting}
              >
                {isExporting && <LoadingOverlay type="cover" spinnerProps={{ size: 16, color: "white" }} />}
                <AiOutlineDownload />
                <span>Export...</span>
              </button>
              <button onClick={e => savePanelRef.current?.enable(e)}>
                <span>Save to...</span>
              </button>
            </>
          )}
        </div>
      </div>
      <div ref={classContainerRef} id={styles["class-container"]}>
        {[...classes.entries()].map(([classId, classData]) => (
          <article
            key={classId}
            onClick={() => {
              if (!isCapturing && modelState !== ModelState.EVALUATE) {
                setSelectedClass(Object.assign({}, classData, { id: classId }));
              }
            }}
            role="option"
            aria-selected={topPrediction === classId || selectedClass?.id === classId}
          >
            <div>
              <input
                value={classData.name}
                onChange={e => {
                  if (new TextEncoder().encode(e.target.value).length >= 64) {
                    e.target.setCustomValidity("Class name must be less than 64 bytes.");
                    notification.push({
                      type: "error",
                      body: "Class name must be less than 64 bytes.",
                      id: "too-long"
                    });
                  } else {
                    e.target.setCustomValidity("");
                    setClasses(classes => {
                      classes.get(classId)!.name = e.target.value;
                    });
                  }
                }}
                placeholder="Click to add class name"
              />
              <button
                className="no-style"
                onClick={() =>
                  setClasses(classes => {
                    classes.delete(classId);
                  })
                }
                disabled={selectedClass?.id === classId && isCapturing}
              >
                <AiOutlineCloseCircle />
                <span>Delete</span>
              </button>
            </div>
            <div className={styles["img-container"]} aria-disabled={modelState !== ModelState.CAPTURE}>
              {!!classData.imgs.length && <div className={styles["img-count"]}>{classData.imgs.length} images</div>}
              <div data-model-imported={modelSource !== ModelSource.UNSAVED}>
                {classData.imgs.map((img, idx) => (
                  <img
                    key={idx}
                    src={img}
                    alt=""
                    onClick={() =>
                      setClasses(classes => {
                        classes.get(classId)!.imgs.splice(idx, 1);
                      })
                    }
                  />
                ))}
              </div>
            </div>
            {modelState === ModelState.EVALUATE && (
              <div>
                <span>{`${Math.round((predictions.get(classId) ?? 0) * 100)}%`}</span>
                <progress value={predictions.get(classId) ?? 0} max={1} />
              </div>
            )}
          </article>
        ))}
        {modelState === ModelState.CAPTURE && (
          <div>
            <button
              id={styles["add-class"]}
              className="no-style"
              onClick={() => setClasses(classes => classes.set(nanoid(8), { name: "", imgs: [] }))}
            >
              Click here to add a new class
            </button>
            {classes.size > 1 && [...classes.values()].every(c => c.imgs.length) && (
              <>
                <span>or</span>
                <div>
                  <button
                    onClick={() => {
                      setTrainingProgress(0);
                      props.onTrainingRequested(classes, (value, done) => {
                        setTrainingProgress(done || value);

                        if (done) {
                          setModelSource(ModelSource.UNSAVED);
                          notification.push({ type: "success", title: "Model trained successfully!" });
                        }
                      });
                    }}
                  >
                    Train model
                  </button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Widget>
  );
}
