import { useCallback, useEffect, useRef, useState } from "react";
import { BiCaretDown, BiDownArrowCircle, BiUpArrowCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { querySelectorAllShadows } from "utils";
import { getUserDialogflow, postUserDialogflow } from "utils/firebase";
import { setIsLoading } from "utils/slices/globalSlice";
import { RootState } from "utils/store";
import "./Dialogflow.css";
import { getAttributesFromTag, getDFMessengerTag } from "./textHelper";

const languages = ["zh-HK", "en-GB", "zh-CN"];
export type Lang = "zh-yue" | "en-GB" | "zh-CN";
const speakLang: Lang[] = ["zh-yue", "en-GB", "zh-CN"];

function Dialogflow() {
  const global = useSelector((state: RootState) => state.global);
  const dangerousInnerHTMLContainerRef = useRef<HTMLDivElement>(null);
  const [filteredVoices, setFilteredVoices] = useState<SpeechSynthesisVoice[]>([]);
  const [selectedVoice, setSelectedVoice] = useState<SpeechSynthesisVoice | null>(null);
  const [responseText, setResponseText] = useState("");
  const [showCBDetails, setShowCBDetails] = useState(true);
  const [userInput, setUserInput] = useState("");
  const [dangerHTML, setDangerHTML] = useState("");
  const [dialogflowInputEl, setDialogflowInputEl] = useState<HTMLInputElement | null>(null);
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
  const [lang, setLang] = useState<Lang>("zh-yue");
  const [dialogflowDetails, setDialogflowDetails] = useState({
    intent: "",
    chatTitle: "",
    agentId: "",
    lang: ""
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (!filteredVoices.length) {
      const voices = window.speechSynthesis.getVoices();
      setFilteredVoices(voices.filter(i => i.voiceURI.startsWith("Google") && languages.includes(i.lang)));
    } else {
      if (!!dialogflowDetails.lang) {
        if (dialogflowDetails.lang === "zh-hk") {
          setLang("zh-yue");
          setSelectedVoice(filteredVoices.find(x => x.lang === "zh-HK")!);
        } else {
          const findMatchedLanguage = filteredVoices.find(i => i.lang.includes(dialogflowDetails.lang));
          const userSpeechLang = speakLang.filter(i => i.includes(dialogflowDetails.lang));
          console.log(speakLang, dialogflowDetails.lang);
          setLang(userSpeechLang.length ? userSpeechLang[0] : speakLang[0]);
          setSelectedVoice(!!findMatchedLanguage ? findMatchedLanguage : filteredVoices[0]);
        }
      } else {
        setSelectedVoice(filteredVoices[0]);
      }
    }
  }, [filteredVoices, dialogflowDetails]);

  useEffect(() => {
    if (!dangerHTML) return;
    const dfMessenger = document.querySelector("df-messenger");
    if (dfMessenger) {
      // Add listener for received message so that it could read every response
      dfMessenger.addEventListener("df-response-received", function (event) {
        //@ts-ignore
        setResponseText(event.detail.response.queryResult.fulfillmentText);
      });
    }
    setDialogflowDetails(getAttributesFromTag(dangerHTML));
    // Click open the chatbot
    const btn = querySelectorAllShadows("#widgetIcon", dangerousInnerHTMLContainerRef.current!);
    (btn[0] as HTMLElement).click();
    // Get input field from chatbot
    const inputContainer = querySelectorAllShadows(".input-box-wrapper", dangerousInnerHTMLContainerRef.current!);
    const inputField = inputContainer[0].querySelectorAll("input")[0];
    setDialogflowInputEl(inputField);
  }, [dangerHTML]);

  //get user's dialogflow element from firebase
  const getDialog = useCallback(
    async (uid: string) => {
      dispatch(setIsLoading(true));
      try {
        const res = await getUserDialogflow(uid);
        if (res === "NOT_FOUND" || !res) {
          setDangerHTML("");
        } else {
          setDangerHTML(res.bot[0]!);
          setUserInput(res.bot[0]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getDialog(global.uid);
  }, [getDialog, global.uid]);

  //after voice recording, set the listened message to dialogflow input
  const setInput = useCallback(
    (value: string) => {
      if (dialogflowInputEl) {
        dialogflowInputEl.value = value;
        //how to hit enter here???
      }
    },
    [dialogflowInputEl]
  );

  const handleStartListen = useCallback(() => {
    resetTranscript();
    SpeechRecognition.startListening({ language: lang, continuous: true });
  }, [lang, resetTranscript]);

  const handleCancelListen = useCallback(() => {
    SpeechRecognition.stopListening();
    resetTranscript();
  }, [resetTranscript]);

  const handleFinishRecording = useCallback(() => {
    setInput(transcript);
    SpeechRecognition.stopListening();
    resetTranscript();
  }, [setInput, resetTranscript, transcript]);

  //read every response when received
  useEffect(() => {
    if (!responseText) return;
    const utterance = new window.SpeechSynthesisUtterance();
    (window as any).utterance = utterance;
    utterance.text = responseText;
    utterance.voice = selectedVoice;
    utterance.onend = async () => {
      await new Promise(resolve => setTimeout(resolve, 1000));
    };
    utterance.rate = 0.9;
    utterance.pitch = 1;
    utterance.volume = 1.5;
    window.speechSynthesis.speak(utterance);
  }, [responseText, selectedVoice]);

  return (
    <section className="borderBox" style={{ maxHeight: "90%" }}>
      {!global.userIsAuth && <h1 style={{ color: "#F55" }}>* This page is for logged in user only</h1>}
      {!!dangerHTML && (
        <div
          ref={dangerousInnerHTMLContainerRef}
          dangerouslySetInnerHTML={{
            __html: dangerHTML
          }}
        />
      )}
      <div className="full-width" style={{ display: "flex", gap: "16px" }}>
        <div className="full-width p-2 chatbotDetails" style={{ height: showCBDetails ? "fit-content" : "24px" }}>
          <div
            className="full-width flex-row-between pointer borderBox"
            onClick={() => setShowCBDetails(!showCBDetails)}
          >
            <div>CHATBOT DETAILS</div>
            <BiCaretDown size={24} />
          </div>
          <div className="flex-row-start my-1 mt-3">
            Intent: <p className="mx-2">{dialogflowDetails.intent}</p>
          </div>
          <div className="flex-row-start my-1">
            Chat title: <p className="mx-2">{dialogflowDetails.chatTitle}</p>
          </div>
          <div className="flex-row-start my-1">
            Agent ID: <p className="mx-2">{dialogflowDetails.agentId}</p>
          </div>
          <div className="flex-row-start my-1">
            Language: <p className="mx-2">{dialogflowDetails.lang}</p>
          </div>

          <div className="flex-column-ss my-1">
            Paste your Dialogflow Messenger codes here
            <textarea
              disabled={!global.userIsAuth}
              className="full-width my-1"
              rows={8}
              placeholder={"Paste dialogflow Messenger codes here"}
              value={userInput}
              onChange={e => setUserInput(e.target.value)}
            />
            <div className="my-2 full-width flex-center">
              <button className="w-50" disabled={!global.userIsAuth} onClick={async () => await getDialog(global.uid)}>
                <BiDownArrowCircle size={24} />
                <div className="secondText mx-1">FETCH</div>
              </button>
              <button
                className="w-50"
                disabled={!global.userIsAuth}
                onClick={async () => {
                  dispatch(setIsLoading(true));
                  await postUserDialogflow(getDFMessengerTag(userInput)).then(() => window.location.reload());
                }}
              >
                <div className="secondText mx-1">SUBMIT</div>
                <BiUpArrowCircle size={24} />
              </button>
            </div>
          </div>
        </div>
        <div
          className="full-width p-2"
          style={{ background: "white", boxShadow: "0 4px 8px #0003", borderRadius: "4px", height: "fit-content" }}
        >
          <div className="mb-2 flex-center">Voice input</div>
          <div className="mb-2 full-width flex-row-around">
            <button className={`flex1 langBut ${lang === "zh-yue" ? "chosen" : ""}`} onClick={() => setLang("zh-yue")}>
              廣東話
            </button>
            <button className={`flex1 langBut ${lang === "en-GB" ? "chosen" : ""}`} onClick={() => setLang("en-GB")}>
              English
            </button>
            <button className={`flex1 langBut ${lang === "zh-CN" ? "chosen" : ""}`} onClick={() => setLang("zh-CN")}>
              普通話
            </button>
          </div>
          <button className="full-width" disabled={!global.userIsAuth} onClick={handleStartListen}>
            START VOICE INPUT
          </button>
        </div>
      </div>
      {listening && (
        <section className="modalBackground" onClick={handleCancelListen}>
          <div className="modal">
            <div style={{ fontSize: "min(2.4vw, 24px)" }}>{lang === "en-GB" ? "Listening..." : "聆聽中..."}</div>
            {!!transcript ? (
              <div style={{ fontSize: "min(2vw, 18px)", maxWidth: "400px", textOverflow: "clip" }}>{transcript}</div>
            ) : (
              <div style={{ fontSize: "min(1.8vw, 18px)", color: "#AAA" }}>{"Your message will be shown here"}</div>
            )}
            <div className="flex-center full-width">
              <button className="voiceModalBtn" onClick={handleFinishRecording}>
                {lang === "en-GB" ? "Confirm" : "確定"}
              </button>
              <button
                className="voiceModalBtn"
                onClick={handleCancelListen}
                style={{ background: "var(--unSelectColor)", color: "#555" }}
              >
                {lang === "en-GB" ? "Cancel" : "取消"}
              </button>
            </div>
          </div>
        </section>
      )}
    </section>
  );
}

export default Dialogflow;
