import type { FirebaseError } from "firebase/app";
import { getDownloadURL, StorageReference } from "firebase/storage";

export function isFirebaseError(obj: any): obj is FirebaseError {
  return obj instanceof Error && "code" in obj;
}

export async function fileExists(ref: StorageReference) {
  try {
    await getDownloadURL(ref);
    return true;
  } catch {
    return false;
  }
}
