import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";

import { deleteDoc, doc, onSnapshot } from "firebase/firestore";
import { useFirestore, useSigninCheck } from "reactfire";

import Graph from "./graph";
import Logs from "./logs";
import Data from "./data";
import FullscreenPrompt from "../../components/fullscreen-prompt";

import _ from "lodash";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import classNames from "classnames";
import styles from "./index.module.scss";

export const COLORS = Object.freeze({
  field1: "#FE7171",
  field2: "#94FE71",
  field3: "#FEB871",
  field4: "#71DBFE",
  field5: "#FEFE71",
  field6: "#9471FE"
});

const enum PromptType {
  INFO = 1,
  DELETE,
  FULLSCREEN
}

export default function GraphChart() {
  const { status: signinCheckStatus, data: signinCheckResult } = useSigninCheck();
  const db = useFirestore();

  const [promptType, setPromptType] = useState<PromptType | null>(null);

  const [dataKey, setDataKey] = useState("");
  const docRef = useMemo(
    () => (dataKey.length ? doc(db, "data", dataKey).withConverter(Data.converter) : null),
    [db, dataKey]
  );
  const [data, setData] = useState<Data | null>(null);

  const deleteData = useMutation(() => deleteDoc(doc(db, "data", dataKey)), {
    onSettled: () => setPromptType(null)
  });

  const downloadCSV = useCallback(() => {
    if (!data) {
      throw new Error("No data to download");
    } else {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(
        data.data.map(datapoint => ({
          timestamp: datapoint.timestamp.seconds,
          ..._.pick(datapoint, [...data.validFields])
        }))
      );
      XLSX.utils.book_append_sheet(wb, ws, "data");
      XLSX.writeFile(wb, dayjs().format("[MuseData_export]_YYYY-MM-DD_HH-mm-ss[.csv]"));
    }
  }, [data]);

  useEffect(() => {
    if (signinCheckStatus === "success" && !signinCheckResult?.signedIn) {
      setPromptType(PromptType.INFO);
    }
  }, [signinCheckResult?.signedIn, signinCheckStatus]);

  useEffect(() => {
    if (docRef) {
      const unsub = onSnapshot(docRef, doc => setData(doc.exists() ? doc.data() : null));

      return unsub;
    } else {
      setData(null);
    }
  }, [docRef]);

  return (
    <>
      {promptType && (
        <FullscreenPrompt
          onClickOutside={() => setPromptType(null)}
          className={classNames(promptType === PromptType.FULLSCREEN && styles["fullscreen-graph-container"])}
        >
          {promptType === PromptType.INFO ? (
            <>
              <big>Data is erased after 24 hours of inactivity. For persistent data storage, register an account.</big>
              <button onClick={() => setPromptType(null)}>Okay</button>
            </>
          ) : promptType === PromptType.DELETE ? (
            <>
              <big>Erase data?</big>
              <div>
                <button className="red" onClick={() => deleteData.mutate()}>
                  Erase
                </button>
                <button onClick={() => setPromptType(null)}>Cancel</button>
              </div>
            </>
          ) : (
            <Graph
              id={styles["fullscreen-graph"]}
              data={data}
              fullscreened={promptType === PromptType.FULLSCREEN}
              onDataKeyChange={setDataKey}
              onClearData={() => setPromptType(PromptType.DELETE)}
              onExportData={() => downloadCSV()}
              onFullScreenToggle={() => setPromptType(null)}
            />
          )}
        </FullscreenPrompt>
      )}
      <div id={styles.main}>
        <Graph
          id={styles.graph}
          data={data}
          fullscreened={promptType === PromptType.FULLSCREEN}
          onDataKeyChange={setDataKey}
          onClearData={() => setPromptType(PromptType.DELETE)}
          onExportData={() => downloadCSV()}
          onFullScreenToggle={() => setPromptType(PromptType.FULLSCREEN)}
        />
        <Logs data={data} />
      </div>
    </>
  );
}
