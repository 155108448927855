import { createRef, MutableRefObject, RefObject, useCallback, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle, AiFillInfoCircle } from "react-icons/ai";
import { MdClose } from "react-icons/md";

import _ from "lodash";
import styles from "./use-notification.module.scss";

type NotificationMessage = {
  id?: string;
  title?: string;
  body?: string;
  type?: "info" | "success" | "warning" | "error";
};

const TYPE_ATTR = Object.freeze({
  info: {
    className: styles.info,
    icon: <AiFillInfoCircle size={24} />
  },
  success: {
    className: styles.success,
    icon: <AiFillCheckCircle size={24} />
  },
  warning: {
    className: styles.warning,
    icon: <AiFillExclamationCircle size={24} />
  },
  error: {
    className: styles.error,
    icon: <AiFillCloseCircle size={24} />
  }
});

interface UseNotificationProps {
  duration?: number;
}

export default function useNotification(p?: UseNotificationProps) {
  const props = { duration: Infinity, ...p };

  // Can't use useImmer here because it doesn't work with DOM node refs :[
  const [messages, setMessages] = useState(
    Array<
      Omit<NotificationMessage, "id"> & {
        id: string;
        willUnmount: boolean;
        ref: RefObject<HTMLDivElement>;
        unmountTimeout: MutableRefObject<NodeJS.Timeout | null>;
      }
    >()
  );

  const clearMessageTimeout = useCallback(
    (id: string, removeForever = false) => {
      const message = messages.find(m => m.id === id);

      if (message && message.unmountTimeout.current) {
        clearTimeout(message.unmountTimeout.current);

        if (removeForever) {
          message.unmountTimeout.current = null;
        }
      }
    },
    [messages]
  );

  const startRemoveTransition = useCallback((id: string) => {
    setMessages(messages => {
      const copy = [...messages];
      const message = copy[copy.findIndex(m => m.id === id)];

      if (message) {
        message.willUnmount = true;
      }

      return copy;
    });
  }, []);

  const remove = useCallback((id: string) => setMessages(draft => draft.filter(m => m.id !== id)), []);

  const push = useCallback(
    (message: NotificationMessage) => {
      const id = message.id ?? _.uniqueId();
      setMessages(draft => {
        if (message.id && draft.find(m => m.id === message.id)) {
          return draft;
        }

        const copy = [...draft];
        copy.push({
          ...message,
          id,
          willUnmount: false,
          unmountTimeout: {
            current: props.duration === Infinity ? null : setTimeout(() => startRemoveTransition(id), props.duration)
          },
          ref: createRef()
        });
        return copy;
      });
    },
    [props.duration, startRemoveTransition]
  );

  return {
    push,
    modal: (
      <div className={styles.main}>
        {[...messages.values()].map(message => (
          <CSSTransition
            key={message.id}
            in={!message.willUnmount}
            nodeRef={message.ref}
            classNames={"float-transition"}
            timeout={250}
            onExited={() => remove(message.id)}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              ref={message.ref}
              className={TYPE_ATTR[message.type ?? "info"].className}
              onMouseEnter={() => {
                if (message.unmountTimeout.current) {
                  clearMessageTimeout(message.id);
                }
              }}
              onMouseLeave={() => {
                if (props.duration !== Infinity && message.unmountTimeout.current) {
                  message.unmountTimeout.current = setTimeout(
                    () => startRemoveTransition(message.id),
                    props.duration
                  );
                }
              }}
            >
              <MdClose
                className={styles["close-btn"]}
                onClick={() => {
                  clearMessageTimeout(message.id, true);
                  startRemoveTransition(message.id);
                }}
              />
              <div>{TYPE_ATTR[message.type ?? "info"].icon}</div>
              <div>
                {message.title && <h4>{message.title}</h4>}
                {message.body && <p>{message.body}</p>}
              </div>
            </div>
          </CSSTransition>
        ))}
      </div>
    )
  };
}
