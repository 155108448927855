import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import "./MuseBot.css";

type LookDirection = "normal" | "left" | "right" | "smile";
type Lang = "zh-yue" | "en-GB" | "zh-CN";

function MuseBot() {
  const [headerAppear, setHeaderAppear] = useState(true);
  const [speechText, setSpeechText] = useState("");
  const [lookDirection, setLookDirection] = useState<LookDirection>("normal");
  const [smile, setSmile] = useState(false);
  const [blink, setBlink] = useState(false);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [openLangSelect, setOpenLangSelect] = useState(false);
  const audio = useRef<HTMLAudioElement | null>(null);
  const [audioLang, setAudioLang] = useState<Lang>("en-GB");
  const navigate = useNavigate();
  // const [speechIntervalId, setSpeechIntervalId] = useState<any>(null);
  const speechIntervalId = useRef<any>();
  // const announcement = new Audio("/SterilizingCaution.mp3");

  const setEyeMotion = () => {
    switch (lookDirection) {
      // case "normal":
      //   return "normal ";
      case "left":
        return "lookLeft ";
      case "right":
        return "lookRight ";
      case "smile":
        return "smileEyes";
    }
  };
  const smileyFace = useCallback(() => {
    setSmile(true);
    setLookDirection("smile");
  }, []);

  useEffect(() => {
    if (lookDirection !== "smile") {
      setSmile(false);
    }
  }, [lookDirection]);

  useEffect(() => {
    if (!blink) {
      const id = setInterval(() => {
        setBlink(true);
      }, 7000);
      setIntervalId(id);
    } else {
      setTimeout(() => {
        setBlink(false);
        clearInterval(intervalId);
      }, 800);
    }
  }, [blink]);

  const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  useEffect(() => {
    const loop = () => {
      _.sample([
        () => setLookDirection("left"),
        () => setLookDirection("right"),
        () => setLookDirection("normal"),
        smileyFace
      ])!();
      // const random = getRandomArbitrary(1500, 3000);
      const random = getRandomArbitrary(3000, 6000);
      setTimeout(() => {
        loop();
      }, random);
    };
    loop();
  }, [smileyFace]);

  const getAudio = useCallback(async () => {
    if (!speechText) return;
    setIsSpeaking(true);
    const res = await axios.post(
      `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyA2sTPCHEF3ft5_TUTfw_baoyPEdleXAA8`,
      {
        input: {
          text: speechText
        },
        voice: {
          languageCode: audioLang,
          ssmlGender: "FEMALE"
        },
        audioConfig: {
          audioEncoding: "MP3"
        }
      }
    );
    const audioContent = res.data.audioContent;
    const mp3 = "data:audio/mp3;base64," + audioContent;
    audio.current = new Audio(mp3);
    audio.current.play();
    audio.current.onended = () => {
      if (audio.current) {
        const id = setTimeout(() => {
          audio.current!.play();
        }, 3000);
        speechIntervalId.current = id;
      }

      // setIsSpeaking(false);
    };
  }, [audioLang, speechText]);

  const handleSelectLang = useCallback((lang: Lang) => {
    setAudioLang(lang);
    setTimeout(() => {
      setOpenLangSelect(false);
    }, 400);
  }, []);

  return (
    <section className="musebotPage borderBox flex-row-start">
      {/* {canPlaySound === null && (
        <div className="fixCenter full-size z5 flex-column-center" style={{ background: "#FFF8" }}>
          <div className="mb-3">Allow to play sound?</div>
          <div className="flex-center">
            <button
              className="mr-1"
              style={{ width: "80px" }}
              onClick={() => {
                setCanPlaySound(true);
              }}
            >
              Yes
            </button>
            <button
              className="ml-1"
              style={{ width: "80px" }}
              onClick={() => {
                setCanPlaySound(false);
              }}
            >
              No
            </button>
          </div>
        </div>
      )} */}
      <div
        className="full-size absolute z1"
        onClick={() => {
          setHeaderAppear(!headerAppear);
          setOpenLangSelect(false);
        }}
      />
      <div className={`foreHead z1 ${headerAppear ? "showHeader" : "hideHeader"}`}>
        <div className="flex1" onClick={() => navigate("/")} onTouchEnd={() => navigate("/")}>
          <div className="bckBtn">
            <MdArrowBackIos size={32} />
          </div>
        </div>
        <div className="full-width relative flex-row-between flex2">
          <div className="relative">
            <button className="mx-2" onClick={() => setOpenLangSelect(!openLangSelect)}>
              Language
            </button>
            {openLangSelect && (
              <div className="flex-column-center full-width absolute" style={{ left: 0 }}>
                <div
                  className={`langBox full-width py-1 my-1 ${audioLang === "zh-yue" ? "currentLang" : ""}`}
                  onClick={() => handleSelectLang("zh-yue")}
                >
                  廣東話
                </div>
                <div
                  className={`langBox full-width py-1 mb-1 ${audioLang === "en-GB" ? "currentLang" : ""}`}
                  onClick={() => handleSelectLang("en-GB")}
                >
                  English
                </div>
                <div
                  className={`langBox full-width py-1 ${audioLang === "zh-CN" ? "currentLang" : ""}`}
                  onClick={() => handleSelectLang("zh-CN")}
                >
                  普通話
                </div>
              </div>
            )}
          </div>
          <input
            className="ttsInput"
            placeholder="Type anything for me to read!"
            value={speechText}
            onChange={e => setSpeechText(e.target.value)}
          />
          <button
            className={`mx-2 ${isSpeaking ? "danger" : ""}`}
            onClick={() => {
              if (!isSpeaking) {
                getAudio();
              } else {
                clearTimeout(speechIntervalId.current);
                audio.current!.pause();
                speechIntervalId.current = null;
                setIsSpeaking(false);
              }
            }}
          >
            {!isSpeaking ? "START" : "STOP"}
          </button>
        </div>
        <div className="flex1 flex-row-end">MuseBot DEMO</div>
        {/* </button> */}
      </div>
      {/* <Webcam className="fixed center" style={{ opacity: 0.15, height: "100vh" }} /> */}
      <div className="flex1 relative full-size">
        <div className={`absolute left eye ${setEyeMotion()} ${blink && "blink"}`}></div>
      </div>
      <div className="flex1 relative full-size">
        <div className={`absolute right eye ${setEyeMotion()} ${blink && "blink"}`}></div>
      </div>
      <div className={`absolute mouth ${smile && "smile"}`}>
        <Mouth />
      </div>
    </section>
  );
}

export default MuseBot;

function Mouth() {
  const strokeWidth = Math.floor((window.innerHeight + window.innerWidth) / 120);
  return (
    <svg width="344" height="58" viewBox="0 0 344 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 12C134 73.6 276.167 37.6667 332 12"
        stroke="#2BFFA6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
}
