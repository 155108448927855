import { HsvaColor, Wheel } from "@uiw/react-color";
import Alpha from "@uiw/react-color-alpha";
import "App.css";
import "pages/speech-to-text/SpeechToText.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserBotSettings, getUserDialogflow, postUserBotSettings } from "utils/firebase";
import { setIsLoading } from "utils/slices/globalSlice";
import { RootState } from "utils/store";
// import { CirclePicker } from "react-color";

function MusebotSettings() {
  const global = useSelector((state: RootState) => state.global);
  const [imageUpload, setImageUpload] = useState<File | null>(null);
  const [renderedImg, setRenderedImg] = useState("");
  const [colors, setColors] = useState("#FFFFFF");
  const [alpha, setAlpha] = useState("FF");
  const [hsva, setHsva] = useState<HsvaColor>({ h: 0, s: 0, v: 0, a: 1 });
  const [message, setMessage] = useState("");
  const [mixedResult, setMixedResult] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (imageUpload) {
      const url = URL.createObjectURL(imageUpload);
      setRenderedImg(url);
    }
  }, [imageUpload]);

  function decimalToHexString(num: number) {
    if (num < 0) {
      num = 0xffffffff + num + 1;
    }

    return num.toString(16).toUpperCase();
  }

  // useEffect(() => {
  //   const result = decimalToHexString(hsva.a);
  //   if (result.startsWith("0.")) {
  //     setColors(colors + result.slice(2, 4));
  //   }
  // }, [colors, hsva]);

  const handleAlphaChange = useCallback((e: HsvaColor) => {
    setHsva(e);
    const result = decimalToHexString(e.a);
    if (result.startsWith("0.")) {
      setAlpha(result.slice(2, 4));
    } else {
      setAlpha("FF");
    }
  }, []);

  useEffect(() => {
    setMixedResult(colors + alpha);
  }, [colors, alpha]);

  const handleSubmit = useCallback(async () => {
    const res = await postUserBotSettings(imageUpload, mixedResult, !renderedImg);
    if (res === "success") {
      setMessage("Save success");
    } else {
      setMessage("Error occurred");
    }
  }, [imageUpload, mixedResult, renderedImg]);

  const getData = useCallback(
    async (uid: string) => {
      dispatch(setIsLoading(true));
      try {
        const res = await getUserDialogflow(uid);
        if (res === "NOT_FOUND" || !res) {
          //do something
          console.log("NOT FOUND");
        } else {
          setRenderedImg(res.imgLink);
          if (!!res.bgColor) {
            setColors(res.bgColor.slice(0, 7));
            const alp = res.bgColor.slice(7, 9);
            setAlpha(alp);
            setHsva({ h: 0, s: 0, v: 0, a: parseInt(alp, 16) / 255 });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (global.uid) {
      getData(global.uid);
    }
  }, [getData, global.uid]);

  return (
    <section className="page flex-column-center borderBox" style={{ width: "100vw" }}>
      <div className="flex-center full-width">
        <section className="sectionContainer flex-column-around flex1" style={{ padding: "24px" }}>
          <div className="full-width flex-column-center">
            <div className="sectionHeader">Upload your favorite animated eyes in gif</div>
            <button className="my-3 full-width" onClick={() => inputRef.current?.click()}>
              UPLOAD IMAGE
            </button>
            <input
              ref={inputRef}
              style={{ display: "none" }}
              type="file"
              accept="image/*"
              multiple={false}
              onChange={e => {
                if (e.target.files) setImageUpload(e.target.files[0]);
              }}
            />
          </div>
          {(!!imageUpload || !!renderedImg) && (
            <>
              <div className="full-width flex-row-around">
                <div className="w-40 flex-column-center">
                  <Wheel color={colors} onChange={color => setColors(color.hex)} />
                </div>
                <div className="w-40 flex-column-center ml-2">
                  <Alpha width={"100%"} hsva={hsva} onChange={e => handleAlphaChange(e as HsvaColor)} />
                </div>
              </div>
              <div className="full-width flex-row-around">
                <div>
                  Skin color
                  <p>{colors}</p>
                </div>
                <div className="ml-2">
                  Skin opacity
                  <p>{hsva.a.toFixed(2)}</p>
                </div>
              </div>
            </>
          )}
        </section>
        <section className="full-width flex-column-center flex1">
          <div className="full-width px-3 borderBox flex-center relative">
            {!!imageUpload || !!renderedImg ? (
              <div className="full-width flex-column-center">
                <button
                  className="full-width mb-1 danger"
                  onClick={() => {
                    setImageUpload(null);
                    setRenderedImg("");
                  }}
                >
                  REMOVE IMAGE
                </button>
                <img
                  style={{ background: mixedResult }}
                  className="uploadImg"
                  src={renderedImg}
                  alt={imageUpload ? imageUpload.name : ""}
                />
              </div>
            ) : (
              <div>
                <p>NO IMAGE UPLOADED YET</p>
              </div>
            )}
          </div>
        </section>
      </div>

      <div className="full-width mt-4 flex-center">
        <button className="w-80" onClick={handleSubmit}>
          SAVE
        </button>
      </div>
    </section>
  );
}

export default MusebotSettings;
