import { useCallback, useRef } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useMutation } from "react-query";
import { CSSTransition } from "react-transition-group";
import { BounceLoader } from "react-spinners";
import { MdCheckCircleOutline, MdEmail, MdMarkEmailRead } from "react-icons/md";

import { sendEmailVerification } from "firebase/auth";
import { useSigninCheck } from "reactfire";

import styles from "./verify.module.scss";

export default function Verify() {
  const [searchParams] = useSearchParams();
  const { status, data: signinCheckResult } = useSigninCheck();
  const confirmResentPRef = useRef<HTMLParagraphElement>(null);
  const resendEmail = useMutation(() => sendEmailVerification(signinCheckResult.user!));

  const resendEmailHandler = useCallback(async () => {
    resendEmail.mutate();
  }, [resendEmail]);

  return status === "success" ? (
    signinCheckResult?.signedIn || searchParams.get("verified") === "true" ? (
      <div id={styles.main}>
        {signinCheckResult?.user?.emailVerified || searchParams.get("verified") === "true" ? (
          <>
            <MdMarkEmailRead size={256} color="#555" />
            <h2>Email Verified</h2>
            <p>You can now return to home or close this page.</p>
            {!signinCheckResult?.signedIn && <p>You may have to log in again.</p>}
          </>
        ) : (
          <>
            <MdEmail size={256} color="#555" />
            <h2>Email Verification</h2>
            <p>
              We sent a verification email to {signinCheckResult?.user?.email}. Until you verify your account, some
              features may not be available.
            </p>
            <p>
              Did not receive the email?{" "}
              <button className="no-style" onClick={resendEmailHandler}>
                <u>Press here to try again.</u>
              </button>
            </p>
            <div id={styles["confirm-resent"]}>
              {resendEmail.isLoading && <BounceLoader size={16} />}
              <CSSTransition
                nodeRef={confirmResentPRef}
                timeout={250}
                classNames="float-transition"
                in={resendEmail.isSuccess}
                exit={false}
                mountOnEnter
                unmountOnExit
              >
                <p ref={confirmResentPRef} aria-hidden={!resendEmail.isSuccess}>
                  <MdCheckCircleOutline /> The verification email has been resent.
                </p>
              </CSSTransition>
            </div>
          </>
        )}
      </div>
    ) : (
      <Navigate to="/register" />
    )
  ) : null;
}
