import { useEffect, useMemo } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { MdLogin, MdLogout, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";
import { useAuth, useSigninCheck } from "reactfire";

import Admin from "pages/admin";
import Auth from "pages/auth";
import Graph from "pages/graph";
import Home from "pages/home";
import Login from "pages/login";
import FaceDetection from "pages/teachable-demo/face";
import ImageClassification from "pages/teachable-demo/image";
import Verify from "pages/verify";
import Dialogflow from "pages/dialogflow";
import MuseBot from "pages/museBot";

import "App.css";
import styles from "App.module.scss";
import _ from "lodash";
import SpeechToText from "pages/speech-to-text";
import TextToSpeech from "pages/text-to-speech";
import { RingLoader } from "react-spinners";
import { setIsAuth } from "utils/slices/globalSlice";
import { RootState } from "utils/store";
import DialogflowMuseBot from "pages/dialogflowMusebot";
import MusebotSettings from "pages/musebot-settings";

export const routeTitlesMap = new Map<string, React.ReactNode>([
  ["", <AiOutlineHome />],
  ["image", "Image Classification"],
  ["face", "Face Detection"],
  ["speech-to-text", "Speech to Text"],
  ["text-to-speech", "Text to Speech"],
  ["musebot", "MuseBot Demo"],
  ["dialogflow-musebot", "Dialogflow MuseBot"],
  ["musebot-settings", "My Musebot Settings"]
]);

const escapeHeader = ["/musebot", "/dialogflow-musebot"];

function Header() {
  const auth = useAuth();
  const { data: signinCheckResult } = useSigninCheck();

  return (
    <header>
      <div id={styles["title-container"]}>
        <span id={styles.title}>MUSE DATA</span>
        <span id={styles.subtitle}>RealTime Data Graph</span>
      </div>
      <div id={styles["user-info"]}>
        {signinCheckResult?.signedIn ? (
          <>
            <span>{signinCheckResult.user.displayName ?? signinCheckResult.user.email}</span>
            {!signinCheckResult.user.emailVerified && <Link to="verify">Verify email</Link>}
            <button className="no-style" onClick={() => auth.signOut()}>
              <MdLogout />
            </button>
          </>
        ) : (
          <>
            <span>Guest</span>
            <Link to="/login" className="no-style">
              <MdLogin />
            </Link>
          </>
        )}
      </div>
    </header>
  );
}

function Breadcrumbs() {
  const location = useLocation();
  const path = useMemo(() => location.pathname.split("/"), [location.pathname]);

  return (
    <nav id={styles.breadcrumbs} aria-label="breadcrumbs">
      {path.map((crumb, idx) => (
        <div key={crumb}>
          {!!idx && <MdOutlineKeyboardArrowRight key={`${idx}_a`} />}
          <Link
            key={idx}
            to={path
              .slice(0, idx + 1)
              .map(p => `${p}/`)
              .join("")
              .slice(0, -1)}
          >
            {routeTitlesMap.get(crumb) ?? _.startCase(crumb)}
          </Link>
        </div>
      ))}
    </nav>
  );
}

function Footer() {
  return <footer>Copyright MuseLab, © {new Date().getFullYear()}</footer>;
}

export default function App() {
  const isLoading = useSelector((state: RootState) => state.global.isLoading);
  const auth = useAuth();
  const { data: signinCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });
  const dispatch = useDispatch();
  const currentUser = getAuth().currentUser;

  useEffect(() => {
    if (currentUser) {
      dispatch(setIsAuth({ isLoggedIn: true, uid: currentUser.uid }));
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    if (
      signinCheckResult?.user?.metadata.lastSignInTime &&
      Date.now() - new Date(signinCheckResult.user.metadata.lastSignInTime).getTime() > 2592000000 // 30 days
    ) {
      signOut(auth);
    }
  });

  return (
    <div id={styles.main}>
      <Header />
      <div id={styles.content}>
        <Routes>
          <Route index element={<Home />} />
          <Route
            path="*"
            element={
              <>
                <Breadcrumbs />
                <Outlet />
              </>
            }
          >
            {signinCheckResult?.hasRequiredClaims && <Route path="admin" element={<Admin />} />}
            <Route path="auth" element={<Auth />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Login type="register" />} />
            <Route path="reset-password" element={<Login type="reset-password" />} />
            <Route path="verify" element={<Verify />} />
            <Route path="graph" element={<Graph />} />
            <Route path="image" element={<ImageClassification />} />
            <Route path="face" element={<FaceDetection />} />
            <Route path="dialogflow" element={<Dialogflow />} />
            <Route path="musebot" element={<MuseBot />} />
            <Route path="dialogflow-musebot" element={<DialogflowMuseBot />} />
            <Route path="musebot-settings" element={<MusebotSettings />} />
            <Route path="speech-to-text" element={<SpeechToText />} />
            <Route path="text-to-speech" element={<TextToSpeech />} />
          </Route>
        </Routes>
      </div>
      {!escapeHeader.includes(window.location.pathname) && <Footer />}
      {isLoading && <Loading />}
    </div>
  );
}

export const Loading = () => {
  return (
    <div className="fixCenter full-size flex-center" style={{ background: "#0006" }}>
      <div className="fixCenter" style={{ transform: "translate(-60%, -60%)" }}>
        <RingLoader color={"#0ffA"} size={"32vw"} loading={true} aria-label="Loading Spinner" data-testid="loader" />
      </div>
      <div className="fixCenter" style={{ fontSize: "40px", color: "#FFF" }}>
        Loading
      </div>
    </div>
  );
};
