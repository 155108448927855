import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { useSigninCheck } from "reactfire";

import { routeTitlesMap } from "App";

import _ from "lodash";
import { updateAdminRoleStaff } from "api";

import dialogflowIcon from "./assets/dialogflow.svg";
import faceIcon from "./assets/face.svg";
import graphIcon from "./assets/graph.svg";
import imageIcon from "./assets/image.svg";
import speechToText from "./assets/speechToText.svg";
import textToSpeech from "./assets/textToSpeech.svg";
import dialogflowMuseBot from "./assets/dialogflowMuseBot.svg";
import musebotSetting from "./assets/musebotSetting.svg";
import museBot from "./assets/musebot.svg";
import styles from "./home.module.scss";
import "./Home.css";

const LINKS = Object.freeze([
  { name: "graph", logo: graphIcon },
  { name: "image", logo: imageIcon },
  { name: "text-to-speech", logo: textToSpeech },
  { name: "speech-to-text", logo: speechToText },
  { name: "face", logo: faceIcon },
  { name: "dialogflow", logo: dialogflowIcon },
  { name: "musebot", logo: museBot },
  { name: "dialogflow-musebot", logo: dialogflowMuseBot },
  { name: "musebot-settings", logo: musebotSetting }
]);

export default function Home() {
  const { status: signinCheckStatus, data: signinCheckResult } = useSigninCheck({ requiredClaims: { admin: true } });
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      signinCheckStatus === "success" &&
      !signinCheckResult?.hasRequiredClaims &&
      ["@muselabs-eng.com", "@muselab.cc"].some(domain => signinCheckResult.user?.email?.endsWith(domain))
    ) {
      signinCheckResult.user?.getIdToken(true).then(token => {
        updateAdminRoleStaff({
          requesterIdToken: token,
          uid: signinCheckResult.user.uid,
          admin: true
        }).then(() => setTimeout(() => window.location.reload(), 1000));
      });
    }
  }, [signinCheckResult?.hasRequiredClaims, signinCheckResult?.user, signinCheckStatus]);

  return (
    <div id={styles.main}>
      <div ref={scrollRef}>
        <button id="leftBtn" onClick={() => scrollRef.current?.scrollBy({ left: -180, behavior: "smooth" })}>
          <BiLeftArrowAlt size={48} />
        </button>
        {signinCheckResult?.hasRequiredClaims && (
          <Link to="/admin" className="no-style">
            <button>Admin</button>
          </Link>
        )}
        {LINKS.map(path => (
          <Link to={`/${path.name}`} className="no-style" key={path.name}>
            <button>
              {path.logo && <img src={path.logo} alt={path.logo} />}
              {routeTitlesMap.get(path.name) ?? _.startCase(path.name)}
            </button>
          </Link>
        ))}
        <button id="rightBtn" onClick={() => scrollRef.current?.scrollBy({ left: 180, behavior: "smooth" })}>
          <BiRightArrowAlt size={48} />
        </button>
      </div>
    </div>
  );
}
