import { useEffect, useRef } from "react";
import { VscCircleFilled } from "react-icons/vsc";

import Widget from "components/widget";
import Data from "./data";
import dayjs from "dayjs";
import { COLORS } from ".";
import styles from "./logs.module.scss";

interface LogProps {
  data: Data | null;
}

export default function Logs(props: LogProps) {
  const logRef = useRef<HTMLDivElement>(null);
  const autoScroll = useRef(true);

  useEffect(() => {
    if (logRef.current && autoScroll.current) {
      logRef.current.scrollTop = logRef.current.scrollHeight - logRef.current.clientHeight;
    }
  }, [props.data]);

  return (
    <Widget id={styles.main} titleElement="Logs">
      <div
        ref={logRef}
        id={styles.logs}
        onScroll={e =>
          (autoScroll.current =
            e.currentTarget.scrollHeight - e.currentTarget.clientHeight - e.currentTarget.scrollTop < 1)
        }
      >
        {!!props.data &&
          props.data.data.map(datapoint => (
            <article key={datapoint.timestamp.nanoseconds}>
              <div>{dayjs.unix(datapoint.timestamp.seconds).format("MM-DD HH:mm:ss")}</div>
              <ul>
                {props.data!.validFields.map(field => (
                  <li key={`${datapoint.timestamp.nanoseconds}_${field}`}>
                    <VscCircleFilled style={{ color: COLORS[field] }} />
                    {field}: {datapoint[field]}
                  </li>
                ))}
              </ul>
            </article>
          ))}
      </div>
    </Widget>
  );
}
