import { useCallback, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import localforage from "localforage";

export type Model = {
  name: string;
  lastUpdated: number;
  zip: Blob;
};

type ModelsQuery = typeof useQuery<(Model | null)[], unknown, (Model | null)[], string[]>;
type ModelsMutation = typeof useMutation<void, unknown, void, unknown>;

interface UseBrowserModelsProps {
  prefix: string;
  modelsQueryOptions?: Parameters<ModelsQuery>[2];
  modelsMutationOptions?: Parameters<ModelsMutation>[2];
}

export default function useBrowserModels(props: UseBrowserModelsProps) {
  const modelsJustFetched = useRef(false);
  const shouldUpdateForage = useRef(false);
  const [models, setModels] = useState(Array<Model | null>(3).fill(null));
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const res = useRef(() => {});

  useQuery(
    ["browser-models-get", props.prefix],
    async () => {
      const localModels = await localforage.getItem(`${props.prefix}-models`);

      return (
        Array.isArray(localModels) &&
        localModels.every(model => model === null || ("name" in model && "lastUpdated" in model && "zip" in model))
          ? localModels
          : Array(3).fill(null)
      ) as typeof models;
    },
    {
      ...props.modelsQueryOptions,
      refetchOnMount: true,
      onSuccess(data) {
        modelsJustFetched.current = true;
        setModels(data);
        props.modelsQueryOptions?.onSuccess?.(data);
      }
    }
  );

  const modelsSetQuery = useMutation(
    ["browser-models-set"],
    async () => {
      await localforage.setItem(`${props.prefix}-models`, models);
      res.current();
    },
    props.modelsMutationOptions
  );

  useEffect(() => {
    if (modelsJustFetched.current) {
      modelsJustFetched.current = false;
      return;
    }

    if (shouldUpdateForage.current) {
      modelsSetQuery.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models]);

  return [
    models,
    useCallback(
      async (action: Parameters<typeof setModels>[0]) => {
        shouldUpdateForage.current = true;
        setModels(typeof action === "function" ? action(models) : action);
        await new Promise<void>(resolve => (res.current = resolve));
      },
      [models]
    )
  ] as const;
}
