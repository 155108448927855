import { useRef, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

import FloatingPanel, { FloatingPanelHook } from "components/floating-panel";
import LoadingOverlay from "components/loading-overlay";

import dayjs from "dayjs";

import type { Model } from "../use-browser-models";
import styles from "./model-list.module.scss";

interface ModelListProps {
  models?: (Omit<Model, "zip"> | null)[];
  slotAmount?: number;
  disallowSelectNull?: boolean;
  onSelect: (idx: number) => void;
  onDelete: (idx: number) => void;
}

export default function ModelList(props: ModelListProps) {
  const confirmDeletePanel = useRef<FloatingPanelHook>(null);
  const [selectedModelIdx, setSelectedModelIdx] = useState(-1);
  const [selectedDeleteIdx, setSelectedDeleteIdx] = useState(-1);

  return (
    <ul id={styles.main} className="no-style">
      <FloatingPanel ref={confirmDeletePanel}>
        <ul>
          <li>Confirm delete? No undo.</li>
          <li
            role="button"
            className="destructive"
            onClick={() => {
              props.onDelete(selectedDeleteIdx);
              confirmDeletePanel.current?.disable(true);
            }}
          >
            Delete
          </li>
        </ul>
      </FloatingPanel>
      {props.models ? (
        props.models.map((model, idx) => (
          <li
            role="option"
            key={model?.name ?? idx}
            onClick={() => {
              setSelectedModelIdx(idx);
              props.onSelect(idx);
            }}
            aria-selected={selectedModelIdx === idx}
            aria-disabled={props.disallowSelectNull && !model}
          >
            {selectedModelIdx === idx && <LoadingOverlay type="cover" spinnerProps={{ size: 24 }} />}
            <div>
              <h3>{model?.name ?? "Empty slot"}</h3>
              <span className="subtext">
                {model
                  ? `Last updated at: ${dayjs(model.lastUpdated).format("YYYY-MM-DD HH:mm")}`
                  : "Click to overwrite"}
              </span>
            </div>
            <button
              type="button"
              className="no-style"
              onClick={e => {
                e.stopPropagation();
                setSelectedDeleteIdx(idx);
                confirmDeletePanel.current?.enable(e);
              }}
              hidden={!model}
            >
              <AiOutlineDelete />
            </button>
          </li>
        ))
      ) : (
        <>Test</>
      )}
    </ul>
  );
}
