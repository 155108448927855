import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AiOutlineReload } from "react-icons/ai";

import { deleteObject, getBlob, getMetadata, list, ref } from "firebase/storage";
import { useSigninCheck, useStorage } from "reactfire";

import ModelList from "../model-list";
import useBrowserModels from "../use-browser-models";
import LoadingOverlay from "components/loading-overlay";

import dayjs from "dayjs";
import styles from "./importer.module.scss";

type DownloadQuery = typeof useQuery<Blob, unknown, Blob, readonly ["model", number]>;

type ImporterProps = {
  onCancel: () => void;
} & Parameters<DownloadQuery>[2] &
  (
    | { type: "browser"; storageBucketPath?: never; prefix: string }
    | { type: "remote"; storageBucketPath: string; prefix?: never }
  );

export default function Importer(props: ImporterProps) {
  const { storageBucketPath, onCancel, ...rest } = props;
  const { data: signinCheckResult } = useSigninCheck();
  const storage = useStorage();

  const [browserModels, setBrowserModels] = useBrowserModels({ prefix: props.prefix ?? "" });
  const remoteModels = useQuery(
    ["file-list"],
    async () => {
      if (props.type === "browser") {
        throw new Error("Use useBrowserModels instead.");
      }

      return await Promise.all(
        (
          await list(ref(storage, `users/${signinCheckResult.user?.uid}/${props.storageBucketPath}`))
        ).items.map(item => getMetadata(item))
      );
    },
    { enabled: props.type === "remote" }
  );

  const [selectedModelIdx, setSelectedModelIdx] = useState(-1);
  const modelQuery = useQuery(
    ["model", selectedModelIdx] as const,
    async () =>
      props.type === "browser"
        ? browserModels[selectedModelIdx]!.zip
        : await getBlob(ref(storage, remoteModels.data![selectedModelIdx].fullPath)),
    { ...rest, enabled: false }
  );

  const modelDeleteHandler = useCallback(
    async (idx: number) => {
      props.type === "browser"
        ? await setBrowserModels(models => [...models.slice(0, idx), null, ...models.slice(idx + 1)])
        : await deleteObject(ref(storage, remoteModels.data![idx].fullPath));
      remoteModels.refetch();
    },
    [props.type, remoteModels, setBrowserModels, storage]
  );

  useEffect(() => {
    if (selectedModelIdx !== -1) {
      modelQuery.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedModelIdx]);

  return (
    <div id={styles.main}>
      <span>Select your model:</span>
      {remoteModels.isError ? (
        <div id={styles.error}>
          Failed to retrieve model list.
          <button className="no-style" onClick={() => remoteModels.refetch()}>
            <span>
              <AiOutlineReload />
              Retry
            </span>
          </button>
        </div>
      ) : remoteModels.isSuccess || props.type === "browser" ? (
        <ModelList
          models={
            props.type === "browser"
              ? browserModels
              : remoteModels.data!.map(item => ({ name: item.name, lastUpdated: dayjs(item.updated).unix() }))
          }
          onSelect={idx => setSelectedModelIdx(idx)}
          onDelete={idx => modelDeleteHandler(idx)}
        />
      ) : (
        <div>
          <LoadingOverlay type="cover" />
        </div>
      )}
      <button className="destructive" onClick={() => props.onCancel()}>
        Cancel
      </button>
    </div>
  );
}
