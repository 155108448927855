import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { applyActionCode } from "firebase/auth";
import { useAuth } from "reactfire";
import { isFirebaseError } from "common/utils/firebase";

export default function Auth() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAuth();

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fn = async () => {
      const oobCode = searchParams.get("oobCode");

      if (!oobCode) {
        navigate("/");
        return;
      }

      const mode = searchParams.get("mode");

      try {
        switch (mode) {
          case "verifyEmail":
            await applyActionCode(auth, oobCode);
            navigate({ pathname: "/verify", search: new URLSearchParams({ verified: "true" }).toString() });
            break;

          case "resetPassword":
            navigate({ pathname: "/reset-password", search: searchParams.toString() });
            break;

          default:
            navigate("/");
        }
      } catch (e: any) {
        if (isFirebaseError(e)) {
          switch (e.code) {
            case "auth/expired-action-code":
              setError("It seems like the link has expired. Please try another link.");
              break;

            case "auth/invalid-action-code":
              setError("It seems like the link is no longer valid. Please try again.");
              break;
          }
        }
      }
    };

    fn();
  }, [auth, navigate, searchParams]);

  return error ? (
    <div>
      <h2>Oops!</h2>
      <p>An error occurred:</p>
      <p>{error}</p>
    </div>
  ) : null;
}
