export const getDFMessengerTag = (tag: string) => {
  return tag.slice(tag.indexOf("<df-messenger"));
};

export const getAttributesFromTag = (tag: string) => {
  const intent = tag.indexOf("intent=");
  const chatTitle = tag.indexOf("chat-title=");
  const agentId = tag.indexOf("agent-id=");
  const lang = tag.indexOf("language-code=");
  const temp2 = tag.slice(intent, chatTitle).slice("intent=".length).replaceAll('"', "").trim();
  const temp3 = tag.slice(chatTitle, agentId).slice("chat-title=".length).replaceAll('"', "").trim();
  const temp4 = tag.slice(agentId, lang).slice("agent-id=".length).replaceAll('"', "").trim();
  const temp5 = tag
    .slice(lang)
    .slice("language-code=".length)
    .replaceAll('"', "")
    .replaceAll("></df-messenger>", "")
    .trim();

  return {
    intent: temp2,
    chatTitle: temp3,
    agentId: temp4,
    lang: temp5
  };
};
