import "App.css";
import axios from "axios";
import _ from "lodash";
import { getAttributesFromTag } from "pages/dialogflow/textHelper";
import { useCallback, useEffect, useRef, useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { querySelectorAllShadows } from "utils";
import { getUserDialogflow } from "utils/firebase";
import { setIsLoading } from "utils/slices/globalSlice";
import { RootState } from "utils/store";
import "./MuseBot.css";

type LookDirection = "normal" | "left" | "right" | "smile";
// const languages = ["zh-HK", "en-GB", "zh-CN"];
export type Lang = "zh-yue" | "en-GB" | "zh-CN";
const speakLang: Lang[] = ["zh-yue", "en-GB", "zh-CN"];

function DialogflowMuseBot() {
  const global = useSelector((state: RootState) => state.global);
  const [headerAppear, setHeaderAppear] = useState(true);
  const [renderedImg, setRenderedImg] = useState("");
  const [faceColor, setFaceColor] = useState("");
  const [lookDirection, setLookDirection] = useState<LookDirection>("normal");
  const [smile, setSmile] = useState(false);
  const [blink, setBlink] = useState(false);
  const [intervalId, setIntervalId] = useState<any>(null);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [audioLang, setAudioLang] = useState<Lang>("zh-yue");
  const [chatBotStarted, setChatBotStarted] = useState(false);
  const navigate = useNavigate();
  const dangerousInnerHTMLContainerRef = useRef<HTMLDivElement>(null);
  const [responseText, setResponseText] = useState("");
  const [dangerHTML, setDangerHTML] = useState("");
  const [dialogflowInputEl, setDialogflowInputEl] = useState<HTMLInputElement | null>(null);
  const [sendEl, setSendEl] = useState<SVGElement | null>(null);

  const { listening, resetTranscript, finalTranscript } = useSpeechRecognition();
  const dispatch = useDispatch();
  // const announcement = new Audio("/SterilizingCaution.mp3");

  //DIALOGFLOW
  //get user's dialogflow element from firebase
  const getDialog = useCallback(
    async (uid: string) => {
      dispatch(setIsLoading(true));
      try {
        const res = await getUserDialogflow(uid);
        if (res === "NOT_FOUND" || !res) {
          setDangerHTML("");
        } else {
          setDangerHTML(res.bot[0]!);
          setRenderedImg(res.imgLink);
          setFaceColor(res.bgColor);
          // setUserInput(res.bot[0]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    },
    [dispatch]
  );

  //call on load for dialogflow
  useEffect(() => {
    if (global.uid) getDialog(global.uid);
  }, [getDialog, global.uid]);

  //set df-messenger
  useEffect(() => {
    if (!dangerHTML) return;
    const dfMessenger = document.querySelector("df-messenger");
    if (dfMessenger) {
      // Add listener for received message so that it could read every response
      dfMessenger.addEventListener("df-response-received", function (event) {
        //@ts-ignore
        setResponseText(event.detail.response.queryResult.fulfillmentText);
      });
    }
    const details = getAttributesFromTag(dangerHTML);
    // setDialogflowDetails(details);
    const filterLang = speakLang.filter(i => i.startsWith(details.lang.slice(0, 2)));
    setAudioLang(filterLang[0]);
    // Click open the chatbot
    const btn = querySelectorAllShadows("#widgetIcon", dangerousInnerHTMLContainerRef.current!);
    (btn[0] as HTMLElement).click();
    // Get input field from chatbot
    const inputContainer = querySelectorAllShadows(".input-box-wrapper", dangerousInnerHTMLContainerRef.current!);
    const inputField = inputContainer[0].querySelectorAll("input")[0];
    setDialogflowInputEl(inputField);
    const sendIcon = querySelectorAllShadows("#sendIcon", dangerousInnerHTMLContainerRef.current!);
    setSendEl(sendIcon[0] as SVGElement);
  }, [dangerHTML]);

  //after voice recording, set the listened message to dialogflow input
  const setInput = useCallback(
    (value: string) => {
      if (dialogflowInputEl) {
        dialogflowInputEl.value = value;
        //how to hit enter here???
        if (sendEl) {
          sendEl.dispatchEvent(new Event("click"));
        }
      }
    },
    [dialogflowInputEl, sendEl]
  );

  const handleStartListen = useCallback(() => {
    resetTranscript();
    SpeechRecognition.startListening({ language: audioLang, continuous: false });
  }, [audioLang, resetTranscript]);

  //function for reading response when received
  const readMessageMp3 = useCallback(
    async (message: string) => {
      if (!chatBotStarted) return;
      setIsSpeaking(true);
      const res = await axios.post(
        `https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=AIzaSyA2sTPCHEF3ft5_TUTfw_baoyPEdleXAA8`,
        {
          input: {
            text: message
          },
          voice: {
            languageCode: audioLang,
            ssmlGender: "FEMALE"
          },
          audioConfig: {
            audioEncoding: "MP3"
          }
        }
      );
      const audioContent = res.data.audioContent;
      const mp3 = "data:audio/mp3;base64," + audioContent;
      const audio = new Audio(mp3);
      audio.play();
      audio.onended = () => {
        setIsSpeaking(false);
        handleStartListen();
      };
    },
    [audioLang, chatBotStarted, handleStartListen]
  );

  //read every response when received
  useEffect(() => {
    if (!!responseText) {
      readMessageMp3(responseText);
    }
  }, [responseText, readMessageMp3]);

  useEffect(() => {
    if (!!finalTranscript) {
      setInput(finalTranscript);
    }
  }, [finalTranscript, setInput]);

  // FACE
  const setEyeMotion = () => {
    switch (lookDirection) {
      // case "normal":
      //   return "normal ";
      case "left":
        return "lookLeft ";
      case "right":
        return "lookRight ";
      case "smile":
        return "smileEyes";
    }
  };
  const smileyFace = useCallback(() => {
    setSmile(true);
    setLookDirection("smile");
  }, []);

  useEffect(() => {
    if (lookDirection !== "smile") {
      setSmile(false);
    }
  }, [lookDirection]);

  useEffect(() => {
    if (!blink) {
      const id = setInterval(() => {
        setBlink(true);
      }, 7000);
      setIntervalId(id);
    } else {
      setTimeout(() => {
        setBlink(false);
        clearInterval(intervalId);
      }, 800);
    }
  }, [blink]);

  const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  useEffect(() => {
    if (!chatBotStarted) return;
    console.log("run loop");
    const loop = () => {
      _.sample([
        () => setLookDirection("left"),
        () => setLookDirection("right"),
        () => setLookDirection("normal"),
        smileyFace
      ])!();
      const random = getRandomArbitrary(1500, 3000);
      setTimeout(() => {
        loop();
      }, random);
    };
    loop();
    return () => {
      setChatBotStarted(false);
    };
  }, [chatBotStarted, smileyFace]);

  return (
    <section className="musebotPage borderBox flex-row-start" style={{ background: !renderedImg ? "#111" : "#FFF" }}>
      {/* {!!dangerHTML && ( */}
      <div
        ref={dangerousInnerHTMLContainerRef}
        dangerouslySetInnerHTML={{
          __html: dangerHTML
        }}
        style={{ display: "none" }}
      />
      <div className="full-size absolute z1" onClick={() => setHeaderAppear(!headerAppear)} />
      <div className={`foreHead z1 ${headerAppear ? "showHeader" : "hideHeader"}`}>
        <div className="flex1" onClick={() => navigate("/")} onTouchEnd={() => navigate("/")}>
          <div className="bckBtn">
            <MdArrowBackIos size={32} color="#222" />
          </div>
        </div>
        <div className="flex1">
          {(!global.uid || !dangerHTML) && !global.isLoading && (
            <div style={{ color: "#f77" }}>User not logged in or do not have a dialogflow set up</div>
          )}
        </div>
        <div className="flex1">{!listening && !isSpeaking && <button onClick={handleStartListen}>Speak</button>}</div>
      </div>
      {/* <Webcam className="fixed center" style={{ opacity: 0.15, height: "100vh" }} /> */}
      {!renderedImg ? (
        <>
          <div className="flex1 relative full-size">
            <div className={`absolute left eye ${setEyeMotion()} ${blink && "blink"}`}></div>
          </div>
          <div className="flex1 relative full-size">
            <div className={`absolute right eye ${setEyeMotion()} ${blink && "blink"}`}></div>
          </div>
          <div className={`absolute mouth ${smile && "smile"}`}>
            <Mouth />
          </div>
        </>
      ) : (
        <div className="flex1 relative full-size" style={{ background: faceColor }}>
          <img src={renderedImg} alt={renderedImg} style={{ width: "100%" }} />
        </div>
      )}
      {!chatBotStarted && (
        <div className="absolute flex-center full-size z1" style={{ background: "#0008" }}>
          <button style={{ color: "#FFF" }} onClick={() => setChatBotStarted(true)}>
            START CHATBOT
          </button>
        </div>
      )}
    </section>
  );
}

export default DialogflowMuseBot;

function Mouth() {
  const strokeWidth = Math.floor((window.innerHeight + window.innerWidth) / 120);
  return (
    <svg width="344" height="58" viewBox="0 0 344 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 12C134 73.6 276.167 37.6667 332 12"
        stroke="#2BFFA6"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
}
