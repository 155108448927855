import { useEffect, useRef } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line, Tooltip, Legend } from "recharts";
import { BsQuestion, BsFullscreen, BsFullscreenExit } from "react-icons/bs";

import { useSigninCheck } from "reactfire";

import Widget, { WidgetProps } from "components/widget";
import DataKeyInput from "components/data-key-input";

import Data from "./data";
import dayjs from "dayjs";
import { COLORS } from ".";
import styles from "./graph.module.scss";

interface GraphProps extends Partial<WidgetProps> {
  data: Data | null;
  fullscreened: boolean;
  onDataKeyChange: (dataKey: string) => void;
  onClearData: () => void;
  onExportData: () => void;
  onFullScreenToggle: () => void;
}

export default function Graph(props: GraphProps) {
  const { data, fullscreened, onDataKeyChange, onClearData, onExportData, onFullScreenToggle, ...rest } = props;
  const { data: signinCheckResult } = useSigninCheck();

  const graphRef = useRef<HTMLDivElement>(null);
  const hintRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (signinCheckResult?.user?.emailVerified) {
      graphRef.current?.style.setProperty("--hint-height", "0px");
    }
  }, [signinCheckResult?.user?.emailVerified]);

  useResizeObserver(hintRef, entry => {
    if (!signinCheckResult?.user?.emailVerified) {
      graphRef.current?.style.setProperty(
        "--hint-height",
        entry.contentRect.height ? `${entry.contentRect.height}px` : "-var(--gap)"
      );
    }
  });

  return (
    <Widget
      {...rest}
      titleElement={
        <>
          Graph
          <DataKeyInput onDataKeyChange={dataKey => props.onDataKeyChange(dataKey)} indicatorPulseInterval={3000} />
        </>
      }
      contentContainerProps={{ id: styles["graph-container"] }}
    >
      <div ref={graphRef} id={styles.graph}>
        {!props.data && <div id={styles["no-data"]}>No data available</div>}
        <ResponsiveContainer>
          <LineChart data={props.data?.data} margin={{ top: 32, right: 32, bottom: 16 }}>
            <CartesianGrid strokeDasharray="6 6" />
            <XAxis
              dataKey="timestamp.seconds"
              name="Time"
              type="number"
              interval="preserveStart"
              tickCount={8}
              domain={["dataMin", "dataMax"]}
              tickFormatter={(value: number, idx) =>
                idx
                  ? `+${value - (props.data?.data[0].timestamp.seconds ?? Number.NaN)}s`
                  : dayjs.unix(value).format("DD/MM HH:mm")
              }
            />
            <YAxis />
            {props.data?.validFields.map(field => (
              <Line
                key={field}
                type="monotone"
                dataKey={field}
                stroke={COLORS[field]}
                strokeWidth={4}
                dot={{ r: 4, strokeWidth: 4 }}
                activeDot={{ r: 8, strokeWidth: 0 }}
                connectNulls
              />
            ))}
            <Tooltip
              wrapperClassName={styles["tooltip-wrapper"]}
              labelFormatter={(label: number) => dayjs.unix(label).format("DD/MM HH:mm:ss")}
            />
            <Legend />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div id={styles.functions}>
        <div>
          <button className="destructive" onClick={() => props.onClearData()}>
            Clear all data
          </button>
          <button onClick={() => props.onExportData()}>Export CSV</button>
        </div>
        <div>
          <button>
            <BsQuestion style={{ transform: "scale(1.5)" }} />
          </button>
          <button onClick={() => props.onFullScreenToggle()}>
            {props.fullscreened ? <BsFullscreenExit /> : <BsFullscreen />}
          </button>
        </div>
      </div>
      {!signinCheckResult?.user?.emailVerified && (
        <div ref={hintRef} id={styles.hint}>
          All data will be erased automatically after 24 hours of inactivity.
        </div>
      )}
    </Widget>
  );
}
