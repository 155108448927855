import { FirebaseOptions } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  doc,
  DocumentData,
  FirestoreDataConverter,
  getDoc,
  getFirestore,
  QueryDocumentSnapshot,
  setDoc,
  SnapshotOptions
} from "firebase/firestore";
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  StorageReference,
  uploadBytes
} from "firebase/storage";
import { IUserDialogflow } from "variables";
import { setIsLoading } from "./slices/globalSlice";
import { store } from "./store";

export const FIREBASE_CONFIG: FirebaseOptions = Object.freeze({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "musedata-53fd6.firebaseapp.com",
  projectId: "musedata-53fd6",
  storageBucket: "musedata-53fd6.appspot.com",
  messagingSenderId: "265984002049",
  appId: "1:265984002049:web:c6771939215a1ac0c81800"
});

export function getGenericConverter<T extends DocumentData>(): FirestoreDataConverter<T> {
  return {
    toFirestore(data: T): DocumentData {
      return data;
    },
    fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
      return snapshot.data(options) as T;
    }
  };
}

export const getUserDialogflow = async (uid: string) => {
  if (!uid) return;
  try {
    const res = await getDoc(doc(getFirestore(), `dialogflow/${uid}`));
    if (res.exists()) {
      const temp = res.data() as IUserDialogflow;
      return temp;
    } else {
      return "NOT_FOUND";
    }
  } catch (error) {
    console.error(error);
  }
};

export const postUserDialogflow = async (tag: string) => {
  store.dispatch(setIsLoading(true));

  try {
    const db = getFirestore();
    const auth = getAuth();
    const uid = auth.currentUser?.uid;
    if (!uid) throw new Error("User not found");
    // await setDoc(doc(db, `dialogflow/${uid}`), { bot: arrayUnion(tag) }, { merge: true });
    await setDoc(doc(db, `dialogflow/${uid}`), { bot: [tag] }, { merge: true });
    return "success";
  } catch (e) {
    console.error(e);
  } finally {
    store.dispatch(setIsLoading(false));
  }
};
export const postUserBotSettings = async (file: File | null, bgColor: string, removeImg?: boolean) => {
  store.dispatch(setIsLoading(true));
  try {
    const auth = getAuth();
    const uid = auth.currentUser?.uid;
    if (!uid) throw new Error("User not found");
    const db = getFirestore();
    const imgLink = file ? await uploadImage(file) : undefined;
    const obj = imgLink
      ? {
          imgLink,
          bgColor
        }
      : !removeImg
      ? { bgColor }
      : { imgLink: "", bgColor: "" };
    await setDoc(doc(db, `dialogflow/${uid}`), obj, { merge: true });
    return "success";
  } catch (e) {
    console.error(e);
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

export const getUserBotSettings = async () => {
  store.dispatch(setIsLoading(true));
  const auth = getAuth();
  const uid = auth.currentUser?.uid;
  try {
    const res = await getDoc(doc(getFirestore(), `dialogflow/${uid}`));
    if (res.exists()) {
      const temp = res.data();
      console.log(temp);
      // return temp;
    } else {
      return "NOT_FOUND";
    }
  } catch (error) {
    console.error(error);
  } finally {
    store.dispatch(setIsLoading(false));
  }
};

export const uploadImage = async (file: File) => {
  const storage = getStorage();
  const storageRef = ref(storage, `botEyes/${new Date().valueOf()}`);
  const res = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(res.ref);
  return url;
};
