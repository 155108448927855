import {
  forwardRef,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { Portal } from "react-portal";
import { useSpring, animated } from "react-spring";
import { useImmer } from "use-immer";
import useResizeObserver from "@react-hook/resize-observer";

import { distanceToElement, onClickedOutside } from "utils";
import Vector2 from "utils/vector2";
import styles from "./floating-panel.module.scss";

interface FloatingPanelProps extends HTMLAttributes<HTMLDivElement> {
  fadeFxRange?: {
    from: number;
    to: number;
  };
  fade?: boolean;
  useMousePosition?: boolean;
  children?: ReactNode;
}

const defaultProps = {
  fadeFxRange: {
    from: 30,
    to: 150
  },
  fade: true,
  useMousePosition: true
};

export interface FloatingPanelHook {
  enable: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disable: (immediate?: boolean) => void;
}

const enum Anchor {
  TOP_LEFT = 1,
  TOP_RIGHT = 1 << 1,
  BOTTOM_LEFT = 1 << 2,
  BOTTOM_RIGHT = 1 << 3
}

const FloatingPanel = forwardRef<FloatingPanelHook, FloatingPanelProps>((_props, ref) => {
  const props = { ...defaultProps, ..._props };
  const { fadeFxRange, fade, children, useMousePosition, ...rest } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const [enabled, setEnabled] = useState(false);
  const [enableEventSource, setEnableEventSource] = useState<HTMLElement | null>(null);
  const [mousePosition, setMousePosition] = useImmer(Vector2.NaN);

  const [anchor, setAnchor] = useState(Anchor.TOP_LEFT);
  const [size, setSize] = useImmer(Vector2.zero);
  const [position, setPosition] = useImmer(Vector2.NaN);
  const [opacity, setOpacity] = useState(1);

  const style = useSpring({
    from: { height: 0 },
    to: { height: enabled ? size.y : 0 },
    reverse: !enabled,
    onRest: () => {
      if (!enabled) {
        setPosition(Vector2.NaN);
      }
    }
  });

  useResizeObserver(containerRef, entry =>
    setSize(draft => {
      draft.x = entry.borderBoxSize[0].inlineSize;
      draft.y = entry.borderBoxSize[0].blockSize;
    })
  );

  const enable: FloatingPanelHook["enable"] = useCallback(e => {
    setEnabled(true);
    setEnableEventSource((e?.target as HTMLElement) ?? null);
  }, []);

  const disable: FloatingPanelHook["disable"] = useCallback(
    (immediate = false) => {
      setEnabled(false);

      if (immediate) {
        style.height.set(0);
        setPosition(Vector2.NaN);
      }
    },
    [setPosition, style.height]
  );

  // Adds mousemove listener on mount
  useEffect(() => {
    const mouseMoveHandler = (e: MouseEvent) => {
      setMousePosition(draft => {
        draft.x = e.clientX;
        draft.y = e.clientY;
      });
    };

    document.addEventListener("mousemove", mouseMoveHandler);

    return () => document.removeEventListener("mousemove", mouseMoveHandler);
  }, [setMousePosition]);

  // Handles on enable and on disable
  useEffect(() => {
    if (enabled) {
      setOpacity(1);

      const unwatchClickedOutside = onClickedOutside(
        [containerRef.current!, ...(enableEventSource ? [enableEventSource] : [])],
        () => setEnabled(false)
      );

      return () => unwatchClickedOutside();
    } else {
      setEnableEventSource(null);
    }
  }, [enableEventSource, enabled, setMousePosition, setPosition]);

  // Sets position and anchor of the panel
  useEffect(() => {
    if (enabled && Vector2.isNaN(position)) {
      const willOverflowX = mousePosition.x + size.x > window.innerWidth;
      const willOverflowY = mousePosition.y + size.y > window.innerHeight;

      setAnchor(
        willOverflowX
          ? willOverflowY
            ? Anchor.BOTTOM_RIGHT
            : Anchor.TOP_RIGHT
          : willOverflowY
          ? Anchor.BOTTOM_LEFT
          : Anchor.TOP_LEFT
      );

      setPosition(draft => {
        draft.x = mousePosition.x;
        draft.y = mousePosition.y;
      });
    }
  }, [enabled, mousePosition, position, setPosition, size.x, size.y]);

  // Sets opacity of the panel
  useEffect(() => {
    if (enabled && props.fade && !Vector2.isNaN(position)) {
      const opacity = Math.mapRange(
        distanceToElement(mousePosition, containerRef.current!),
        props.fadeFxRange.from,
        props.fadeFxRange.to,
        1,
        0,
        true
      );

      setOpacity(opacity);

      if (!opacity) {
        setEnabled(false);
      }
    }
  }, [enabled, mousePosition, position, props.fade, props.fadeFxRange.from, props.fadeFxRange.to]);

  useImperativeHandle(ref, () => ({ enable, disable }));

  const OptionalPortal = useCallback(
    ({ useMousePosition, children }: { useMousePosition: boolean; children: ReactNode }) =>
      useMousePosition ? <Portal>{children}</Portal> : <>{children}</>,
    []
  );

  return (
    <OptionalPortal useMousePosition={props.useMousePosition}>
      <div className={styles.main}>
        <animated.div
          style={{
            ...style,
            width: size.x,
            opacity,
            ...(Vector2.isNaN(position)
              ? {}
              : {
                  top: anchor & (Anchor.TOP_LEFT | Anchor.TOP_RIGHT) ? position.y : position.y - size.y,
                  left: anchor & (Anchor.TOP_LEFT | Anchor.BOTTOM_LEFT) ? position.x : position.x - size.x
                })
          }}
          aria-hidden={!enabled}
        >
          <div
            {...rest}
            ref={containerRef}
            style={{
              ...(anchor & (Anchor.TOP_LEFT | Anchor.TOP_RIGHT) ? { bottom: 0 } : { top: 0 }),
              ...rest.style
            }}
          >
            {props.children}
          </div>
        </animated.div>
      </div>
    </OptionalPortal>
  );
});

FloatingPanel.defaultProps = defaultProps;

export default FloatingPanel;
