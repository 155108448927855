import { useEffect, useRef } from "react";
import { MaybePromise } from "common/types";

type LoopCallback = () => MaybePromise<void | (() => void)>;

export default function useLoop(condition: boolean, loopFn: LoopCallback, delay = 0) {
  const loopFnRef = useRef<LoopCallback>(loopFn);

  useEffect(() => {
    loopFnRef.current = loopFn;
  }, [loopFn]);

  useEffect(() => {
    if (condition) {
      let onEnd: void | (() => void) = undefined;
      let loopFlag = true;
      let breakFlag = false;

      if (delay) {
        const interval = setInterval(() => {
          loopFlag = true;

          if (breakFlag) {
            clearInterval(interval);
          }
        }, delay);
      }

      const loop = async () => {
        if (loopFlag) {
          onEnd = await loopFnRef.current();
          loopFlag = false;
        }

        if (breakFlag) {
          onEnd?.();
        } else {
          requestAnimationFrame(loop);
        }
      };

      loop();

      return () => {
        breakFlag = true;
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, delay]);
}
